import React from "react";
import ViewListIcon from "@material-ui/icons/ViewList";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { ObjectToQueryString, QueryStringToObject } from "../../../components/helper";
import { useHistory } from "react-router";

const ProductCategoryViewToggle = (props) => {
  const history = useHistory();

  const handleAlignment = (event, newView) => {
    if (newView === null) return;
    let URIparams = QueryStringToObject();
    history.push({
      search: `${ObjectToQueryString({
        ...URIparams,
        list: newView,
        showall: true,
      })}`,
    });
  };
  return (
    <>
      <ToggleButtonGroup
        value={QueryStringToObject().list === "true"}
        exclusive
        onChange={handleAlignment}
        aria-label="view"
        size="small"
      >
        <ToggleButton value={false} aria-label="grid">
          <ViewModuleIcon />
        </ToggleButton>
        <ToggleButton value={true} aria-label="list">
          <ViewListIcon />
        </ToggleButton>
      </ToggleButtonGroup>
    </>
  );
};

export default ProductCategoryViewToggle;
