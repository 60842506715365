import {
  DASHBOARD_ERROR,
  DASHBOARD_INIT,
  DASHBOARD_SUCCESS,
  DELETE_ERROR,
  DELETE_INIT,
  DELETE_SUCCESS,
  DOWNLOAD_REPORT_ERROR,
  DOWNLOAD_REPORT_INIT,
  DOWNLOAD_REPORT_SUCCESS,
  DOWNLOAD_ERROR_REPORT_INIT,
  DOWNLOAD_ERROR_REPORT_SUCCESS,
  DOWNLOAD_ERROR_REPORT_ERROR,
  SET_PAGE,
  SHOW_MODAL,
  SET_QUERY_PARAMS,
  HIDE_STATUS,
  DOWNLOAD_FILE_INIT,
  DOWNLOAD_FILE_SUCCESS,
  DOWNLOAD_FILE_ERROR
} from './types';
import {
  AS_DASHBOARD_UPLOAD,
  AS_DASHBOARD_ERROR_REPORT,
  AS_DASHBOARD_EXPORT,
  AS_DASHBOARD_DOWNLOAD_FILE
} from '../../../config/api';
import axiosInstance from '../../../Utils/axios';


export const fetchDashboardData = (index = 0, isUpload = false, search='', ordering='') => async (
  dispatch
) => {
  try {
    dispatch({
      type: DASHBOARD_INIT,
      payload: { status: { type: 'info', text: 'fetching Batches' } }
    });
    
    let queryParams = new URLSearchParams()
    queryParams.set('page', index + 1)
    queryParams.set('is_active', true)
    if(search) {
      queryParams.set('search', search)
    }
    if(ordering) {
      queryParams.set('ordering', ordering)
    }
    
    await axiosInstance
      .get(`${AS_DASHBOARD_UPLOAD}?${queryParams}`)
      .then((response) =>
        dispatch({
          type: DASHBOARD_SUCCESS,
          payload: { ...response.data.data,pageNo:(index+1), status: {} },
        })
      );
  } catch (error) {
    dispatch({
      type: DASHBOARD_ERROR,
      payload: { status: { type: 'error', text: 'Error Fetching Batches' } },
    });
  }
};

export const autoRefreshDashboardData = (index = 0, isUpload = false, search='', ordering='') => async (
  dispatch
) => {
  try {
    dispatch({
      type: DASHBOARD_INIT,
      payload: { status: { type: 'info', text: 'Refreshing Batches' } }
    });
    
    let queryParams = new URLSearchParams()
    queryParams.set('page', index + 1)
    queryParams.set('is_active', true)
    if(search) {
      queryParams.set('search', search)
    }
    if(ordering) {
      queryParams.set('ordering', ordering)
    }
    
    await axiosInstance
      .get(`${AS_DASHBOARD_UPLOAD}?${queryParams}`)
      .then((response) => {
        dispatch({
          type: DASHBOARD_SUCCESS,
          payload: { ...response.data.data,pageNo:(index+1), status: { type: 'success', text: 'Refresh Complete' } },
        });
        setTimeout(() => dispatch({type: HIDE_STATUS}), 4000);
      });
  } catch (error) {
    dispatch({
      type: DASHBOARD_ERROR,
      payload: { status: { type: 'error', text: 'Error Fetching Batches' } },
    });
  }
};

export const handleShowModal = (payload = false) => {
  return {
    type: SHOW_MODAL,
    payload,
  };
};


export const downloadUploadedFile = (id, name) => async (dispatch) => {
  try {
    dispatch({
      type: DOWNLOAD_FILE_INIT,
      payload: { status: { type: 'info', text: 'downloading file' }, id: id },
    });
    const response = await axiosInstance.get(`${AS_DASHBOARD_DOWNLOAD_FILE}${id}`);
    if (response.data) {
      let a = document.createElement('a');
      a.href = `data:text/csv;charset=utf-8,${escape(response.data)}`
      a.download= `${name}_${id}.csv`
      a.click();
      dispatch({
        type: DOWNLOAD_FILE_SUCCESS,
        payload: { status: { type: 'success', text: 'file downloaded' }, id: null },
      });
    }
  } catch (error) {
    dispatch({
      type: DOWNLOAD_FILE_ERROR,
      payload: { status: { type: 'error', text: 'error downloading file' }, id: null },
    });
  }
  setTimeout(() => dispatch({type: HIDE_STATUS}), 3000);
};


export const downloadReport = (id, type, callback) => async (dispatch) => {
  try {
    dispatch({
      type: DOWNLOAD_REPORT_INIT,
      payload: { status: { type: 'info', text: 'downloading report' }, id: id },
    });
    

    const response = await axiosInstance.get(`${AS_DASHBOARD_EXPORT}${id}`, {
      params: {
        'export_type': type
      }
    });

    if (response.data.data.spreadsheet_url) {

      let a = document.createElement('a');
      a.href = response.data.data.spreadsheet_url;
      a.click();

      dispatch({
        type: DOWNLOAD_REPORT_SUCCESS,
        payload: { status: { type: 'success', text: 'report downloaded' }, id: null },
      });
      callback(false)

    }

  } catch (error) {
    dispatch({
      type: DOWNLOAD_REPORT_ERROR,
      payload: { status: { type: 'error', text: 'error downloading report' }, id: null },
    });
    callback(false)
  }
};

export const downloadErrorReport = (id, failedCount, batch, callback) => async (dispatch) => {
  try {
    dispatch({
      type: DOWNLOAD_ERROR_REPORT_INIT,
      payload: { status: { type: 'info', text: 'downloading error report...' }, id: id },
    });

    const response = await axiosInstance.get(`${AS_DASHBOARD_ERROR_REPORT}?batch_id=${id}&export_type=csv`, {
      params: {
        'export_type': 'csv'
      }
    });

    if (response.data.data.spreadsheet_url) {

      let a = document.createElement('a');
      a.href = response.data.data.spreadsheet_url;
      a.click();

      dispatch({
        type: DOWNLOAD_ERROR_REPORT_SUCCESS,
        payload: { status: { type: 'success', text: 'error report downloaded' }, id: null },
      });
      callback(false)

    }

  } catch (error) {
    dispatch({
      type: DOWNLOAD_ERROR_REPORT_ERROR,
      payload: { status: { type: 'error', text: 'error downloading report' }, id: null },
    });
    callback(false)
  }
};

export const deleteBatch = (id, pageNo, callback, queryParams) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_INIT,
      payload: { status: { type: 'info', text: 'deleting batch' }, id: id }
    })

    const response = await axiosInstance.delete(`${AS_DASHBOARD_UPLOAD}${id}/`);

    if (response.data.data.message) {
      dispatch({
        type: DELETE_SUCCESS,
        payload: { status: { type: 'success', text: 'batch deleted' }, id: null }
      })
      callback(false)
      setTimeout(() => {
        dispatch(fetchDashboardData(pageNo,false,queryParams['searchQ'],queryParams['sortQ']));
      }, 2500);

    }

  } catch (error) {
    dispatch({
      type: DELETE_ERROR,
      payload: { status: { type: 'error', text: 'error deleting batch' }, id: null }
    });
    callback(false)
  }
}
export const handlePageNo = (payload = 0) => {
  return {
    type: SET_PAGE,
    payload,
  };
};

export const setQueryParams = (payload) => {
  return { 
    type: SET_QUERY_PARAMS,
    payload
  };
};