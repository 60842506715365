import React, { useState } from "react";
import styled from "styled-components";
import { Box, Button } from "@material-ui/core";
import ShowToast from "../../../../../../../commonComponents/toast";
import ConfirmPrompt from "../../../../../../../commonComponents/confirmPrompt";

export const ProductViewListWrapper = styled.div`
  width: ${({ $isfilterOpen }) =>
    $isfilterOpen ? "calc(100vw - 436px)" : "calc(100vw - 266px)"};
`;

export const FetchErrorMessage = (callback) => {
  ShowToast(
    'Something went wrong...',
    "error",
    "bottom-right",
    7000
  );
};

export const SaveSuccessMessage = () => {
  ShowToast('Changes saved successfully',
    "success",
    "bottom-right",
    7000
  );
};

export const SaveErrorMessage = () => {
  ShowToast(
    'Failed to save changes. Try again',
    "error",
    "bottom-right",
    7000
  );
};

export const RenderProductAction = ({ show, changesArray, saveEdits }) => {
  const [showWarning, setshowWarning] = useState(false);
  if (show) {
    return (
      <Box>
        <Box component="span" mr={2}>
          <Button
            aria-controls="saveEditBtn"
            style={{
              minWidth: 120,
            }}
            variant="contained"
            color="primary"
            size="small"
            id="saveEditsBtn"
            onClick={() => {
              if (changesArray.length <= 0) {
                ShowToast('You have not made any changes',
                  "info",
                  "top-right"
                );
              } else {
                saveEdits("edits");
              }
            }}
          >
            Save Edits
          </Button>
        </Box>
        <Box component="span">
          <Button
            aria-controls="saveEditBtn"
            style={{ minWidth: 120 }}
            variant="contained"
            size="small"
            id="saveAllBtn"
            onClick={() => {
              const name = localStorage.getItem("name");
              if (localStorage.getItem(`${name} no-prompt`)) {
                return saveEdits("all");
              }
              setshowWarning(true);
            }}
          >
            Save All
          </Button>
        </Box>
        {showWarning && (
          <ConfirmPrompt
            title="Confirm Save All"
            isSaveAll
            message={
              <>
                <Box>
                  All Products displayed on this page (edited or not edited)
                  will be considered as reviewed and finalized.
                </Box>
                <Box mt={1}>Do you want to save all the products?</Box>
              </>
            }
            confirmCallback={(val, noPromptPreference) => {
              if (val) {
                saveEdits("all");
              }
              if (noPromptPreference) {
                const name = localStorage.getItem("name");
                localStorage.setItem(`${name} no-prompt`, true);
              }
              setshowWarning(false);
            }}
          />
        )}
      </Box>
    );
  }
  return <> </>;
};
