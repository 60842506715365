/**
 * BASE based on the production or development run
 */
export const BASE_API = 
  process.env.NODE_ENV === "development" ? "https://dsg-dev.attributesmart.iaproducts.ai/api" : "/api";

/**
 * Platform API goes below
 */

//please create api based on structure of below attribute api
export const FETCH_TENANT = "/tenant/google_tenant_id/";
export const VERIFY_TOKEN = "/user/verify-token/";
export const USER_ROLES = "/user/userrole/";
export const USER_DETAILS = "/user/userdetail/";
export const USER_PERMISSIONS = "/user/permissions/";
export const USER_GROUPS = "/user/groups/";
export const USER_ROLE_GROUP_MAP = "/user/map_user_role_group/";

export const FILTER_ELEMENTS = "/common/filter_elements/";
export const FILTER_PRODUCT_DETAILS = "/product/product_details/";

export const POSTHOG_API = "https://posthog-dev.iaproducts.ai";

/**
 * Attribute Smart API goes below
 */

export const AS_BASE = "/attribute-smart";

//Dashboard API
export const AS_DASHBOARD_UPLOAD = AS_BASE + "/upload/";
export const AS_DASHBOARD_EXPORT = AS_BASE + "/export_product_attributes/";
export const AS_DASHBOARD_ERROR_REPORT = AS_BASE + "/export-image-error/";
export const AS_DASHBOARD_DOWNLOAD_FILE = AS_BASE + "/download_original/"

//Explore Summary API
export const AS_EXPLORE_SUMMARY = AS_BASE + "/explore-api/";

//Explore GET PRODUCT ID API
export const AS_GET_PRODUCT_ID = AS_BASE + "/get_product_id/";

//Explore category API
export const AS_EXPLORE_CATEGORY = AS_BASE + "/explore-category-api/";
export const AS_EXPLORE_VARIANT_CATEGORY =
  AS_BASE + "/explore-variant-category/";
export const AS_EXPLORE_FILTER = AS_BASE + "/explore-category-filter-api/";
export const AS_EXPLORE_VARIANT_FILTER =
  AS_BASE + "/explore-variant-category-filter/";
export const AS_SINGLE_EDIT_SAVE = AS_BASE + "/product_attributes/";
export const AS_FETCH_ATTRIBUTES = AS_BASE + "/fetch_tenant_attributes/";

//Product Detail Page
export const AS_PRODUCT_DETAIL = AS_BASE + "/product-view/";

//Workflow Page API
export const STATES = "/workflow/state/";
export const TRANSITION = "/workflow/transitionmeta/";
export const WORKFLOW = "/workflow/workflow/";
export const TRANSITIONAPPROVAL = "/workflow/transitionapproval/";
