import { toast } from "react-toastify";

const ShowToast = (
  message = "",
  type = "info",
  position = "bottom-right",
  autoClose = 5000,
  closeOnClick = true,
  pauseOnHover = true
) =>
  toast(message, {
    type,
    position,
    autoClose,
    hideProgressBar: false,
    closeOnClick,
    pauseOnHover,
    draggable: false,
    progress: undefined,
  });

export default ShowToast;
