import React from "react";
import CategoryFilter from "./CategoryFilter";
import { useHistory } from "react-router-dom";
import { getValueForKey, handleClearAll } from "../../components/helper";
import { Typography } from "@material-ui/core";
import { Inbox } from "react-feather";
import styled from "styled-components";

const Filters = ({
  filterData,
  filterLoader,
  confidence,
  filtersOpen,
  handleFiltersOpen,
}) => {
  const history = useHistory();

  const renderFilter = () =>
    filterData.length ? (
      filterData?.map(({ id, label, is_variant, values, type }) => {
        return values?.length ? (
          <CategoryFilter
            key={id}
            parentId={id}
            parentLabel={label}
            isVariant={is_variant}
            values={values}
            type={type}
            confidence={confidence}
            filtersOpen={!!getValueForKey(id)}
          />
        ) : null;
      })
    ) : (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingTop: "20px",
        }}
      >
        <Inbox size={50} style={{ color: "grey" }} />
        <Typography
          size={96}
          style={{ color: "grey", fontSize: "14px" }}
          gutterBottom
        >
          No Data
        </Typography>
      </div>
    );

  return filtersOpen ? (
    <FiltersContainer $loading={filterLoader}>
      <FilterHeader>
        <FilterButton
          id="exCatBtnOpenFilters"
          $open={true}
          onClick={() => handleFiltersOpen(!filtersOpen)}
        >
          <i className="fa fa-filter fa-lg" aria-hidden="true" />
        </FilterButton>

        <Typography variant="caption" style={{ fontSize: 15 }}>
          Filters
        </Typography>
        <ClearAllText onClick={() => handleClearAll(history)}>
          <Typography variant="caption" id="clearAllBtn">
            Clear All
          </Typography>
        </ClearAllText>
      </FilterHeader>
      {renderFilter()}
    </FiltersContainer>
  ) : (
    <FilterButton
      id="exCatBtnOpenFilters"
      $open={false}
      onClick={() => handleFiltersOpen(!filtersOpen)}
    >
      <i className="fa fa-filter fa-lg" aria-hidden="true" />
    </FilterButton>
  );
};

export default Filters;

export const FilterButton = styled.button`
  outline: none;
  align-self: flex-start;
  background-color: #d3d3d39c;
  border-radius: 6px;
  margin-right: 4px;
  margin-top: ${({ $open }) => ($open ? "0" : "20px")};
  border: none;
  cursor: pointer;
  display: flex;
  padding: 8px 8px;
  margin-left: 8px;
  .fa-filter {
    color: #5a7c90;
  }
  &:hover,
  :active {
    background-color: #d3d3d3eb;
  }
`;

const FiltersContainer = styled.div`
  margin-top: 20px;
  height: ${({ $loading }) =>
    $loading ? "var(--attribute-smart-filter-container-width)" : "max-content"};
  min-width: var(--attribute-smart-filter-container-width);
  width: var(--attribute-smart-filter-container-width);
  transition: width 6s ease;
`;

const FilterHeader = styled.div`
  font-size: 16px;
  font-weight: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 13px;
`;

export const ClearAllText = styled.span`
  color: #0098ff;
  margin-left: auto;
  margin-right: 8px;
  cursor: pointer;
`;
