import { AS_FETCH_ATTRIBUTES } from "../../../config/api";
import axiosInstance from "../../../Utils/axios";
import { ATTRIBUTES_INIT, ATTRIBUTES_SUCCESS, ATTRIBUTES_ERROR } from "./types";

export const fetchAttributes = () => async (dispatch) => {
  try {
    dispatch({
      type: ATTRIBUTES_INIT,
    });

    const response = await axiosInstance.get(AS_FETCH_ATTRIBUTES);
    dispatch({
      type: ATTRIBUTES_SUCCESS,
      payload: response.data.data.tenant_attribute_list,
    });
  } catch (error) {
    dispatch({
      type: ATTRIBUTES_ERROR,
    });
  }
};
