import { Box, Button, Tooltip } from "@material-ui/core";
import { useState } from "react";
import { connect } from "react-redux";
import { Prompt } from "react-router-dom";
import ConfirmPrompt from "../../../../../../../commonComponents/confirmPrompt";
import ShowToast from "../../../../../../../commonComponents/toast";

const ProductActions = ({
  show,
  changesArray,
  saveEdits,
  handleConfidence,
  handleResetPageSize,
}) => {
  const [showWarning, setshowWarning] = useState(false);

  return show ? (
    <Box>
      <Prompt
        when={!!changesArray.length}
        message={() => {
          handleConfidence();
          handleResetPageSize();
          return "";
        }}
      />
      <Box component="span" mr={2}>
        <Tooltip title="Saves edited products ONLY (marked in yellow)" arrow>
          <Button
            aria-controls="saveEditBtn"
            style={{
              minWidth: 120,
            }}
            variant="contained"
            color="primary"
            size="small"
            id="saveEditsBtn"
            onClick={() => {
              if (changesArray.length <= 0) {
                ShowToast("You have not made any changes", "info", "top-right");
              } else {
                saveEdits("edits", false, changesArray);
              }
            }}
          >
            Save Edits
          </Button>
        </Tooltip>
      </Box>
      <Box component="span">
        <Tooltip
          title="Saves edited products and marks other products displayed on this page as Reviewed"
          arrow
        >
          <Button
            aria-controls="saveEditBtn"
            style={{ minWidth: 120 }}
            variant="contained"
            size="small"
            id="saveAllBtn"
            onClick={() => {
              const name = localStorage.getItem("name");
              if (localStorage.getItem(`${name} no-prompt`)) {
                return saveEdits("all", false, changesArray);
              }
              setshowWarning(true);
            }}
          >
            Finalize All
          </Button>
        </Tooltip>
      </Box>
      {showWarning && (
        <ConfirmPrompt
          title="Confirm Finalize All"
          isSaveAll
          message={
            <>
              <Box>
                All Products displayed on this page (edited or not edited) will
                be considered as reviewed and finalized.
              </Box>
              <Box mt={1}>Do you want to finalize all the products?</Box>
            </>
          }
          confirmCallback={(val, noPromptPreference) => {
            if (val) {
              saveEdits("all", false, changesArray);
            }
            if (noPromptPreference) {
              const name = localStorage.getItem("name");
              localStorage.setItem(`${name} no-prompt`, true);
            }
            setshowWarning(false);
          }}
        />
      )}
    </Box>
  ) : null;
};

const mapStateToProps = (state) => ({
  changesArray: state.exploreCategoryReducer.changesArray,
});

export default connect(mapStateToProps)(ProductActions);
