import {
  ATTRIBUTES_INIT,
  ATTRIBUTES_SUCCESS,
  ATTRIBUTES_ERROR,
} from "../actions/types";

const initialState = {
  attributes: {},
  attributeLoader: false,
  attributeDataExist: false,
};

const attributeSmartReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case ATTRIBUTES_INIT:
      return { ...state, attributeLoader: true, attributeDataExist: false };
    case ATTRIBUTES_SUCCESS:
      return {
        ...state,
        attributes: action.payload,
        attributeLoader: false,
        attributeDataExist: true,
      };
    case ATTRIBUTES_ERROR:
      return { ...state, attributeLoader: false, attributeDataExist: false };

    default:
      return state;
  }
};

export default attributeSmartReducer;
