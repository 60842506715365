import React from "react";
import {
  Box,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core";
import { ScrollSync, ScrollSyncPane } from "react-scroll-sync";
import clsx from "clsx";
import ImageCardWrapper from "./ImageCardWrapper";
import SelectDropdown from "./SelectDropdown";
import { ListViewSkeleton } from "./ImageSkeleton";

const ProductViewList = ({ response, ...props }) => {
  const classes = useStyles();

  if (props.isloading || !props.attributes[1]) {
    return <ListViewSkeleton useStyles={useStyles} />;
  }

  if (!response?.data?.length) {
    return <div className={classes.noData}>No images found...</div>;
  }

  return (
    <Paper className={classes.root}>
      <ScrollSync>
        <div>
          <ScrollSyncPane group="horizontal">
            <div
              className={`product-list-view ${classes.headerContainer}`}
              id="ProductHeaderValues"
            >
              <TableCell
                className={clsx(classes.th, classes.fixedColumnHeader)}
                component="span"
              >
                Product
              </TableCell>
              {response?.column_id_list?.map((column, i) => (
                <TableCell component="span" className={classes.th} key={column}>
                  {props.attributes[column].name}
                </TableCell>
              ))}
            </div>
          </ScrollSyncPane>
          <ScrollSyncPane group="horizontal">
            <div className={classes.tableContainer}>
              <Table>
                <TableBody>
                  {response?.data?.map((product, i) => (
                    <TableRow
                      key={
                        response.is_variant
                          ? product.variant_id
                          : product.product_id
                      }
                      className={classes.tr}
                      id={`exProdImageList${i}`}
                    >
                      <TableCell
                        className={clsx(classes.td, classes.fixedColumn)}
                        style={{ maxWidth: 240 }}
                      >
                        <Box>
                          <ImageCardWrapper
                            {...props}
                            listView
                            productDetails={{
                              id: product.product_id,
                              product_id: response.is_variant
                                ? product.variant_id
                                : product.product_id,
                              product_attribute_mapping_id:
                                product.product_attribute_mapping_id,
                              tenant_attribute_value:
                                product.tenant_attribute_value,
                              product_attribute_status:
                                product.product_attribute_status,
                              product_status: product.product_status,
                              product_display_status:
                                product.product_display_status,
                              L1_category_detail: product.L1_category_detail,
                              L2_category_detail: product.L2_category_detail,
                              mapping_strategy: product.mapping_strategy,
                            }}
                            imageUrl={product.thumbnail_image_url}
                            imageUrlHighReso={product.image_url}
                            index={i}
                            handleRecategorization={props.saveEdits}
                          />
                        </Box>
                      </TableCell>
                      {product?.tenant_attributes?.map((attribute, index) => (
                        <TableCell
                          className={classes.td}
                          style={{ maxWidth: 240 }}
                        >
                          <SelectDropdown
                            listView
                            isSmartCopy={props.isSmartCopy}
                            handleSmartCopyCount={props.handleSmartCopyCount}
                            handleSmartCopyValue={props.handleSmartCopyValue}
                            smartCopy={props.smartCopy}
                            dropdownData={
                              props.attributes[attribute.tenant_attribute_id]
                                ?.tenant_attribute_value_list
                            }
                            selected={attribute.selected}
                            dropdownStatus={props.dropdownStatus}
                            attribute={{
                              ...attribute,
                              product_id: response.is_variant
                                ? product.variant_id
                                : product.product_id,
                            }}
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </ScrollSyncPane>
        </div>
      </ScrollSync>
    </Paper>
  );
};

export default ProductViewList;

const useStyles = makeStyles((theme) => ({
  gridWrapper: {
    fontSize: 14,
    height: "fit-content",
    overflowY: "visible",
    margin: "0 -40px",
    display: "inline-flex",
    justifyContent: "start",
    flexWrap: "wrap",
    msOverflowStyle: "none",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },

    "& .product-attribute-select": {
      width: 200,
      "&:last-child": {
        marginBottom: 40,
      },
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  box: {
    overflowX: "scroll",
  },
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    // overflowX: "auto",
    maxHeight: "100%",
  },
  headerContainer: {
    display: "flex",
    textAlign: "center",
    position: "sticky",
    top: "50px",
    overflowX: "auto",
    zIndex: 4,
    background: "#fff",
    "&::-webkit-scrollbar": {
      height: "15px",
    },
    // "&::-webkit-scrollbar-track": {
    //   backgroundColor: "#98989840",
    // },
    // "&::-webkit-scrollbar-corner": {
    //   backgroundColor: "#98989840",
    // },
    "&::-webkit-scrollbar-thumb": {
      // backgroundColor: "#58acf4a8",
      backgroundClip: "padding-box",
      border: "2px solid transparent",
      borderRadius: "10px",
    },

    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "rgb(112, 112, 112)",
    },

    "&::-webkit-scrollbar-thumb:active": {
      backgroundColor: "rgb(128, 128, 128)",
    },
    "&::-webkit-scrollbar-button:single-button": {
      backgroundColor: "#98989840",
      display: "block",
      backgroundSize: "10px",
      backgroundRepeat: "no-repeat",
    },
    "&::-webkit-scrollbar-button:single-button:horizontal:decrement": {
      height: "12px",
      width: "12px",
      backgroundPosition: "3px 3px",
      backgroundImage: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='0,50 50,100 50,0'/></svg>")`,
    },

    "&::-webkit-scrollbar-button:single-button:horizontal:decrement:hover": {
      backgroundImage: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='0,50 50,100 50,0'/></svg>")`,
    },

    " &::-webkit-scrollbar-button:single-button:horizontal:decrement:active": {
      backgroundImage: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='0,50 50,100 50,0'/></svg>")`,
    },

    "&::-webkit-scrollbar-button:single-button:horizontal:increment": {
      height: "12px",
      width: "12px",
      backgroundPosition: "3px 3px",
      backgroundImage: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='0,0 0,100 50,50'/></svg>")`,
    },

    "&::-webkit-scrollbar-button:single-button:horizontal:increment:hover": {
      backgroundImage: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='0,0 0,100 50,50'/></svg>")`,
    },

    " &::-webkit-scrollbar-button:single-button:horizontal:increment:active": {
      backgroundImage: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='0,0 0,100 50,50'/></svg>")`,
    },
  },
  tableContainer: {
    overflowX: "auto",
    "&::-webkit-scrollbar": {
      height: "0px",
    },
  },
  container: {
    maxHeight: 440,
  },
  sticky: {
    position: "sticky",
    top: 150,
  },
  tr: {
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
  },
  th: {
    minWidth: 240,
    width: "100%",
    textAlign: "center",
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
    borderRight: "1px solid rgba(224, 224, 224, 1)",
  },
  td: {
    minWidth: 240,
    maxWidth: 240,
    textAlign: "center",
    borderBottom: "none",
    borderRight: "none",
  },
  fixedColumn: {
    position: "sticky",
    left: 0,
    zIndex: 1,
    background: "white",
  },
  fixedColumnHeader: {
    position: "sticky",
    left: 0,
    zIndex: 2,
    background: "white",
  },
  noData: {
    color: "grey",
    margin: 40,
    fontSize: 14,
  },
}));
