import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import { useHistory } from "react-router";
import { ObjectToQueryString, QueryStringToObject } from "../../../components/helper";

const useStyles = makeStyles({
  root: {
    width: 300,
    display: "flex",
    justifyContent: "space-between",
    "& > *": {
      marginRight: "10px",
    },
  },
});

function valuetext(value) {
  return `${value}`;
}

const DiscreteSlider = ({ imageCount, resetPageSize }) => {
  const classes = useStyles();
  const history = useHistory();
  const [count, setcount] = useState(imageCount || 40);

  const handleChange = (event, newValue) => {
    if (count !== newValue) {
      setcount(newValue);
    }
  };

  const handleCommit = (event, newValue) => {
    const urlParams = QueryStringToObject();
    if (urlParams.image_count !== newValue) {
      urlParams.image_count = newValue;
      urlParams.page = 1;
      history.push({
        search: `?${ObjectToQueryString(urlParams)}`,
      });
    }
  };

  useEffect(() => {
    setcount(imageCount || 40);
  }, [resetPageSize, imageCount]);

  return (
    <div className={classes.root} style={{ float: "right" }}>
      <Typography id="discrete-slider" variant="overline" gutterBottom>
        #Products
      </Typography>
      <Slider
        defaultValue={40}
        getAriaValueText={valuetext}
        aria-labelledby="discrete-slider"
        valueLabelDisplay="auto"
        step={10}
        marks
        min={40}
        max={100}
        onChange={handleChange}
        onChangeCommitted={handleCommit}
        value={count}
      />
      <Typography variant="overline" gutterBottom style={{ margin: 0 }}>
        {count}
      </Typography>
    </div>
  );
};

export default DiscreteSlider;
