import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import ShowToast from "../../../../../commonComponents/toast";
import { AS_GET_PRODUCT_ID } from "../../../../../config/api";
import axiosInstance from "../../../../../Utils/axios";
import SearchBox from "./SearchBox";
import LoadingOverlay from "../../../../../commonComponents/loadingOverlay";

const SearchProductId = ({ idprefix }) => {
  const history = useHistory();
  const { batch } = useParams();

  const [searchQ, setSearchQ] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!searchQ) return;
    let didCancel = false;

    const fetchData = async () => {
      setLoading(true);
      try {
        let payload = {
          batch_id: batch,
          product_sku_id: searchQ.trim(),
        };

        const result = await axiosInstance.post(AS_GET_PRODUCT_ID, payload);

        if (!didCancel && result?.data?.data?.product_id) {
          document.getElementById(`${idprefix}SearchInput`).value = "";
          setSearchQ("");
          setLoading(false);

          history.push(
            `/attributesmart/dashboard/${batch}/${result.data.data.category}/${result.data.data.sub_category}/search/${result.data.data.product_id}`
          );
        }
      } catch (error) {
        if (!didCancel) {
          setLoading(false);
          if (error?.response?.data?.data?.message) {
            ShowToast(error?.response?.data?.data?.message, "error");
          } else {
            ShowToast("Something Went Wrong", "error");
          }
        }
      }
    };

    fetchData();

    return () => {
      didCancel = true;
    };
  }, [searchQ]);

  return (
    <Box
      display="flex"
      alignItems="center"
      marginLeft="10px"
      marginBottom="12px"
    >
      <LoadingOverlay active={loading} />
      <SearchBox
        searchQ={searchQ}
        setSearchQ={setSearchQ}
        idprefix={idprefix}
      />
    </Box>
  );
};

export default SearchProductId;
