import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { RotateCw } from "react-feather";
import ReactImageMagnify from "react-image-magnify";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { Tabs, Tab, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {
    minHeight: "10px !important",
    // position: "absolute",
    zIndex: "2",
    // top: 0,
    height: "30px",
    marginTop: "-15px",
    marginLeft: "20px",
    "& .MuiTab-root": {
      minHeight: "20px !important",
      height: "30px",
      fontSize: "13px",
      lineHeight: "1",
      fontWeight: 600,
      color: "rgba(0,0,0,0.8)",
      padding: "20px 0",
      zIndex: 4,
      "& .MuiTab-wrapper": {
        marginBottom: "9px",
      },
    },
    "& .Mui-selected": {
      color: "#4f677b",
      opacity: 1,
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "#4f677b",
    },
  },
}));
function DetailImages({ images, inputText = [] }) {
  const [index, setindex] = useState(0);
  const [isloading, setisloading] = useState(true);
  const [tabValue, setTabValue] = useState(0);
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const onThumbClick = (params) => {
    if (index !== params) {
      setindex(params);
      setisloading(true);
    }
  };

  return (
    <div>
      {inputText.length > 0 && (
        <Tabs
          value={tabValue}
          onChange={handleChange}
          aria-label="simple tabs example"
          className={classes.root}
        >
          <Tab label="Images" id="productDetailImagesTab" />
          <Tab label="Text" id="productDetailTextTab" />
        </Tabs>
      )}
      {tabValue === 0 ? (
        <ImageColumn>
          <DFlex>
            <ThumbList>
              {images.map((image, i) => (
                <Thumb
                  active={index === i}
                  key={i}
                  id={"thumb" + i}
                  onClick={() => onThumbClick(i)}
                >
                  <img src={image.cropped} alt="" />
                </Thumb>
              ))}
            </ThumbList>
          </DFlex>
          <Image id="productDetailMainImg">
            {isloading && (
              <span className="rotate-svg">
                <RotateCw />
              </span>
            )}

            <ReactImageMagnify
              {...{
                smallImage: {
                  alt: "Product_Image",
                  isFluidWidth: true,
                  src: images[index].original,
                  onLoad: () => setisloading(false),
                },
                largeImage: {
                  src: images[index].original,
                  width: 1300,
                  height: 1300,
                },

                enlargedImageContainerDimensions: { width: 500, height: 600 },
                enlargedImageContainerStyle: {
                  position: "absolute",
                  top: "-50px",
                  borderRadius: "6px",
                  border: "1px solid lightgray",
                  backgroundColor: "white",
                },
                imageStyle: {
                  width: "auto !important",
                  height: "auto",
                  maxHeight: "450px",
                  maxWidth: "450px",
                  boxShadow: "-2px 2px 6px 1px rgba(0, 0, 0, 0.24)",
                  borderRadius: "3px",
                  "& span": {
                    width: "100px",
                  },
                  "& img": {
                    width: "auto",
                  },
                },
                isHintEnabled: !isloading,
                hintComponent: () => (
                  <div
                    style={{
                      width: "100%",
                      position: "absolute",
                      bottom: "25px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        alignItems: "center",
                        backgroundColor: "rgb(51, 51, 51)",
                        borderRadius: "10px",
                        opacity: "0.9",
                        display: "flex",
                        padding: "4px",
                      }}
                    >
                      <AddCircleOutlineIcon style={{ color: "white" }} />
                      <span
                        style={{
                          fontSize: "13px",
                          color: "white",
                          paddingLeft: "5px",
                        }}
                      >
                        Hover to zoom
                      </span>
                    </div>
                  </div>
                ),
              }}
            />
          </Image>
        </ImageColumn>
      ) : (
        <ImageColumn>
          <DFlex>
            <ThumbList>
              {inputText.map((image, i) => (
                <Thumb
                  active={index === i}
                  key={i}
                  id={"thumb" + i}
                  onClick={() => onThumbClick(i)}
                >
                  <img src={images[0].cropped} alt="" />
                </Thumb>
              ))}
            </ThumbList>
          </DFlex>
          <TextDescription id="productDetailDescription">
            {inputText[index].text}
          </TextDescription>
        </ImageColumn>
      )}
    </div>
  );
}

DetailImages.propTypes = {
  images: PropTypes.array,
};

export default DetailImages;

const ImageColumn = styled.div`
  padding-top: 30px;
  min-width: 680px;
  max-width: 680px;
  flex: 2 1 auto;
  position: sticky;
  display: flex;
  justify-content: flex-start;
  flex-flow: row;
  top: 150px;
  bottom: 0;
  z-index: 2;
  align-self: flex-start;
`;

const DFlex = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

const TextDescription = styled.div`
  width: 540px;
  height: 520px;
  overflow-wrap: break-word;
  padding: 20px 0px 20px 10px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const Image = styled(DFlex)`
  padding: 10px 20px 10px 20px;
  position: relative;
  width: 540px;
  align-items: center;
  justify-content: center;

  .rotate-svg {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    color: white;
    background: #0000007a;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    svg {
      animation-name: spin;
      animation-duration: 1500ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

const ThumbList = styled.div`
  display: flex;
  padding: 0 0 0 10px;
  flex-flow: column;
  overflow-y: auto;
  height: 500px;

  ::-webkit-scrollbar {
    width: 4px;
    height: 3px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #989898;
  }

  &::-webkit-scrollbar-thumb {
    background: #58acf4a8;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #98989840;
    box-shadow: none;
    margin-top: 10px;
  }
`;

const Thumb = styled.div`
  padding: 1px;
  margin: 8px;
  border: 3px solid #eaeaea;
  border-radius: 2px;

  img {
    width: 36px;
    height: 60px;
  }

  &:hover {
    border: 3px solid #58acf4a8;
    cursor: pointer;
  }

  ${(props) => props.active && "border: 3px solid #58acf4a8;"}
`;
