import { InputAdornment } from "@material-ui/core";
import React from "react";
import CancelIcon from "@material-ui/icons/Cancel";
import TextField from "@material-ui/core/TextField";
import Search from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/core/styles";

const SearchBox = ({
  searchQ,
  setSearchQ,
  idprefix,
  callback = () => null,
}) => {
  const classes = useStyles();

  return (
    <form
      onSubmit={(e) => {
        callback(0);
        e.preventDefault();
        let inputVal = document.getElementById(`${idprefix}SearchInput`).value;
        setSearchQ(inputVal);
      }}
    >
      <TextField
        id={`${idprefix}SearchInput`}
        label="Search Product"
        margin="dense"
        variant="outlined"
        InputLabelProps={{
          classes: {
            root: classes.labelRoot,
            focused: classes.labelFocused,
          },
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment
              position="start"
              className={classes.inputAdornmentRoot}
            >
              {document.getElementById(`${idprefix}SearchInput`)?.value ? (
                <CancelIcon
                  id={`${idprefix}ClearSearchBtn`}
                  className={classes.searchButton}
                  style={{ color: "gray", align: "center", cursor: "pointer" }}
                  onClick={(e) => {
                    document.getElementById(`${idprefix}SearchInput`).value =
                      "";
                    callback(0);
                    setSearchQ("");
                  }}
                />
              ) : null}
              <button
                type="submit"
                value="Submit"
                id={`${idprefix}SearchBtn`}
                className={classes.searchButton}
              >
                <Search
                  id={`${idprefix}SearchBtnIcon`}
                  style={{
                    color: searchQ ? "#3f51b5e5" : "gray",
                    align: "center",
                  }}
                />
              </button>
            </InputAdornment>
          ),
          classes: { root: classes.inputRoot, adornedEnd: classes.adornedEnd },
        }}
      />
    </form>
  );
};

export default SearchBox;

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    fontSize: 14,
  },
  labelRoot: {
    fontSize: 13,
    "&$labelFocused": {
      color: "#3f51b5",
    },
  },
  adornedEnd: {
    paddingRight: "4px !important",
  },
  labelFocused: {},
  inputAdornmentRoot: {
    marginRight: "0 !important",
    paddingTop: "3px",
    "& button": {
      outline: "none",
      border: "none",
      textTransform: "none",
      backgroundColor: "white",
    },
  },
  searchButton: {
    "&:hover": {
      transform: "scale(1.1)",
    },
    "&:active": {
      opacity: "0.5",
    },
  },
}));
