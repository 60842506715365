import React from "react";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import * as FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import { AS_DASHBOARD_UPLOAD, BASE_API } from "../../../../../config/api";
import { keys, values } from "lodash";

registerPlugin(FilePondPluginFileValidateType);

const fileTypes = ["text/csv"];

const BatchUpload = (props) => {
  const onInit = (params) => {
    //console.log('onInit', params)
  };

  const onLoad = (params) => {
    props.status({
      type: "success",
      label: "Batch",
      value: "created successfully",
    });
    setTimeout(() => {
      props.onSuccess();
    }, 500);
  };

  const onError = (params) => {
    let response = JSON.parse(params);
    props.status({
      type: "error",
      label: keys(response.data)[0],
      value: values(response.data)[0][0],
    });
  };

  const onData = (params) => {
    props.status({
      type: "info",
      label: "",
      value: "Uploading...",
    });
    params.append("batch_name", props.batch);
    return params;
  };

  const onUpdateFiles = (params) => {
    if (params?.length === 0) {
      props.status("");
    }
  };

  const fileTypeValidation = (source, type) => {
    console.log("fileTypeValidation", type);
    if (window.navigator.platform.indexOf("Win") > -1) {
      // if (type === 'application/vnd.ms-excel') {
      type = "text/csv";
      // }

      return Promise.resolve(type);
    }
    return Promise.resolve(type);
  };

  return (
    <div>
      <FilePond
        name="file"
        allowMultiple={false}
        maxFiles={1}
        credits={false}
        labelIdle={
          'Drag & Drop your files or <span class="filepond--label-action"> Browse </span> <div style=font-size:12px> Recommended csv file upto 10,000 rows</div>'
        }
        acceptedFileTypes={fileTypes}
        fileValidateTypeDetectType={fileTypeValidation}
        server={{
          process: {
            url: BASE_API + AS_DASHBOARD_UPLOAD,
            method: "POST",
            headers: {
              authorization: localStorage.getItem("token"),
            },
            oninit: () => onInit,
            onload: (response) => onLoad(response),
            onerror: (response) => onError(response),
            ondata: (formData) => onData(formData),
          },
        }}
        onupdatefiles={onUpdateFiles}
      />
    </div>
  );
};

export default BatchUpload;
