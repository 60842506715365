import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import { Box } from "@material-ui/core";
import { getValueForKey, ObjectToQueryString } from "../components/helper";

const useStyles = makeStyles({
  root: {
    maxWidth: 220,
    minWidth: 220,
    borderRadius: 0,
    "&:hover": {
      boxShadow: "0px 0px 2px 1px #58acf4",
    },
  },
  media: {
    height: 160,
    objectFit: "contain",
  },
  cardContent: {
    padding: "4px 16px",
  },
  title: {
    fontSize: 12,
    width: "100%",
    whiteSpace: "nowrap",
  },
  tags: {
    display: "flex",
    alignItems: "center",
  },
  tagCount: {
    marginLeft: 2,
    color: "#454545",
    fontSize: 12,
  },
  footerTags: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: 12,
    fontWeight: "bold",
    color: "#7a7a7a",
    "& > :first-child": {
      color: "#adbac1",
    },
  },
});

const CardWrapper = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const history = useHistory();

  const updatedPath = () => {
    const l1 = encodeURIComponent(props.category);
    const l2 = encodeURIComponent(props.data.l2_category);
    const view = Number(getValueForKey("view")) || 0;

    const searchPayload = {
      view,
      status: "Pending",
      list: !!view,
      showall: !!view,
    };
    history.push({
      pathname: `${history.location.pathname}/${l1}/${l2}/view`,
      search: ObjectToQueryString(searchPayload),
    });
  };
  return (
    <Card
      className={classes.root}
      ref={ref}
      onClick={updatedPath}
      id={"L2_" + props.data.l2_category}
    >
      <CardActionArea>
        <CardMedia
          className={classes.media}
          component="img"
          alt={props.data.l2_category}
          height="160"
          image={props.data.image_url}
          title={props.data.l2_category}
        />
        <CardContent className={classes.cardContent}>
          <div>
            <Typography
              variant="overline"
              display="block"
              className={classes.title}
            >
              <Box component="div" textOverflow="ellipsis" overflow="hidden">
                {props.data.l2_category}
              </Box>
            </Typography>
          </div>

          {/* <Typography
                        variant="overline"
                        display="block"
                        className={classes.title}
                    >
                        {props.data.l2_category}
                    </Typography> */}
          <BodyTags>
            <div className={classes.tags}>
              <PhotoLibraryIcon fontSize="small" style={{ color: "#ADBAC1" }} />
              <Typography variant="caption" className={classes.tagCount}>
                {props.data.count}
              </Typography>
            </div>
            <div className={classes.tags}>
              <LocalOfferIcon fontSize="small" style={{ color: "#ADBAC1" }} />
              <Typography variant="caption" className={classes.tagCount}>
                {props.data.tag_status}
              </Typography>
            </div>
          </BodyTags>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Footer>
          <Progress progress={props.data.progress}>
            <div className="status"></div>
          </Progress>
          <div className={classes.footerTags}>
            <Typography variant="caption">Reviewed</Typography>
            <Typography variant="caption">{props.data.progress}%</Typography>
          </div>
        </Footer>
      </CardActions>
    </Card>
  );
});

export default CardWrapper;

const BodyTags = styled.div`
  display: flex;
  height: 20px;

  & > :not(:first-child) {
    margin-left: 6px;
    border-left: 1px solid #e4e4e4;
    padding-left: 6px;
  }
`;

const Footer = styled.div`
  width: 220px;
  padding: 0 8px;
`;

const Progress = styled.div`
  color: #000 !important;
  background-color: #f1f1f1 !important;

  .status {
    height: 4px;
    width: ${(props) => props.progress}%;
    color: #000 !important;
    background-color: #0097c3 !important;
  }
`;
