import React, { useEffect, useCallback, useReducer, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import "rc-slider/assets/index.css";
import Products from "./products/Products";
import styled from "styled-components";
import {
  AS_EXPLORE_FILTER,
  AS_EXPLORE_VARIANT_FILTER,
} from "../../../../../config/api";
import SelectedFilters from "./filters/SelectedFilters";
import axiosInstance from "../../../../../Utils/axios";
import {
  fetchPayload,
  ObjectToQueryString,
  handleClearAll,
  QueryStringToObject,
  getValueForKey,
} from "../components/helper";
import HeaderBreadCrumbs from "../../../../../Utils/HeaderBreadCrumbs";
import HomeIcon from "@material-ui/icons/Home";
import { Container } from "../../../../../commonComponents/viewUtils";
import { connect } from "react-redux";
import { Box } from "@material-ui/core";
import StickyContainer from "../../../../../commonComponents/stickyContainer";
import { fetchAttributes } from "../../../actions/attributeSmartActions";
import LoadingOverlay from "../../../../../commonComponents/loadingOverlay";
import Filters from "./filters/filters";

const initialState = {
  filterData: [],
  filterLoader: false,
  confidence: false,
  updateFilters: false,
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case "GET_FILTER_DATA":
      return {
        ...state,
        filterData: action.payload,
        filterLoader: false,
      };

    case "FILTER_LOADER":
      return {
        ...state,
        filterLoader: action.payload,
        filterData: action.payload ? state.filterData : [],
      };
    case "RESET_CONFIDENCE":
      return {
        ...state,
        confidence: !state.confidence,
      };
    default:
      return state;
  }
};

const ExploreProducts = ({ attributeLoader, attributeDataExist, ...props }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { l1, l2, batch } = useParams();
  const history = useHistory();
  const [filtersOpen, setFiltersOpen] = useState(true);

  useEffect(() => {
    if (attributeDataExist) return;
    props.fetchAttributes();
  }, []);

  const pushChanges = (isSaved, checkResult, URIparams) => {
    if (isSaved && checkResult) {
      return true;
    } else if (isSaved) {
      history.push({
        search: `?${ObjectToQueryString(URIparams)}`,
      });
    }
    return false;
  };

  const onEditSave = (response, checkResult = false) => {
    const data = response.data.data?.message ? [] : response.data.data;
    if (!data.length) {
      return handleClearAll(history);
    }
    let isSaved = false;
    let attributeId = data.map(({ id }) => id);
    let URIparams = QueryStringToObject();
    for (let key in URIparams) {
      const indexOfKey = attributeId.indexOf(Number(key));
      if (indexOfKey < 0 || isNaN(key)) break;
      let paramArr = URIparams[key].split("|");

      for (let value of paramArr) {
        const values = data[indexOfKey].values.map(({ label }) => label);
        if (!values.includes(value)) {
          const updatedParam = paramArr.filter((e) => e !== value).join("|");
          URIparams = {
            ...URIparams,
            [key]: updatedParam,
          };
          if (paramArr.length === 1) {
            delete URIparams[key];
          }
          isSaved = true;
        }
      }
    }
    return pushChanges(isSaved, checkResult, URIparams);
  };

  const handleFiltersOpen = (params) => {
    setFiltersOpen(params);
  };

  const handleConfidence = useCallback(() => {
    dispatch({
      type: "RESET_CONFIDENCE",
    });
  }, []);

  const prepareFiltersFetch = () => {
    let payload = fetchPayload();
    payload.batch_id = batch;
    payload.l1 = decodeURIComponent(l1);
    payload.l2 = decodeURIComponent(l2);
    payload.view = Number(getValueForKey("view")) || 0;

    return payload;
  };

  useEffect(() => {
    const fetchFilterCategoryData = async () => {
      try {
        let payload = prepareFiltersFetch();
        dispatch({
          type: "FILTER_LOADER",
          payload: true,
        });
        window.scrollTo(0, 110);
        const api =
          getValueForKey("type") === "true"
            ? AS_EXPLORE_VARIANT_FILTER
            : AS_EXPLORE_FILTER;

        const response = await axiosInstance.post(api, payload);
        if (onEditSave(response, true)) {
          return onEditSave(response);
        }
        dispatch({
          type: "GET_FILTER_DATA",
          payload: response.data.data?.message ? [] : response.data.data,
        });
      } catch (e) {
        dispatch({
          type: "FILTER_LOADER",
          payload: false,
        });
      }
    };

    fetchFilterCategoryData();
  }, [window.location.search]);

  const optionsArray = [
    {
      label: "Dashboard",
      id: "dashboard_bc",
      icon: <HomeIcon fontSize="small" />,
      action: () => history.push(`/attributesmart/dashboard/`),
    },
    {
      label: `Explore`,
      id: "explore_bc",
      action: () => history.push(`/attributesmart/dashboard/${batch}?view%3D${
        Number(getValueForKey("view")) || 0}`),
    },
    {
      label: decodeURIComponent(l2),
      id: "category_bc",
      action: () => null,
    },
  ];

  const headerOptions = {
    headerTitle: <HeaderBreadCrumbs options={optionsArray} />,

    className: "",
  };

  return (
    <Container
      className="dashboard"
      id="dashboard"
      headerOptions={headerOptions}
    >
      <LoadingOverlay active={attributeLoader || state.filterLoader} />
      <Box id="exCategory" display="flex">
        <div>
          <StickyContainer offsetTop={50} offsetBottom={20}>
            <Filters
              filterData={state.filterData}
              filterLoader={state.filterLoader}
              confidence={state.confidence}
              filtersOpen={filtersOpen}
              handleFiltersOpen={handleFiltersOpen}
            />
          </StickyContainer>
        </div>
        <ParentToolbarContainer id="exCatToolbarWrapper">
          <SelectedFilters filterData={state.filterData} />

          <Products
            singleEdit={
              (getValueForKey("productId") &&
                getValueForKey("type") !== "true") ||
              (getValueForKey("variantId") && getValueForKey("type") === "true")
            }
            handleConfidence={handleConfidence}
            filtersOpen={filtersOpen}
          />
        </ParentToolbarContainer>
      </Box>
    </Container>
  );
};
const mapStateToProps = (state) => ({
  attributeLoader: state.attributeSmartReducer.attributeLoader,
  attributeDataExist: state.attributeSmartReducer.attributeDataExist,
});

export default connect(mapStateToProps, { fetchAttributes })(ExploreProducts);

const ParentToolbarContainer = styled.div`
  height: max-content;
  // background-color: #fff;
  overflow-y: visible;
  display: flex;
  padding: 40px;
  padding-top: 20px;
  margin-bottom: 100px;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 4px;
  .toolbar {
    font-size: 14px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .slider-container {
      font-size: 14px;
      width: 300px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .sort-by-container {
      display: flex;
      align-items: center;
      .sort-by-select {
        font-size: 14px;
        width: 220px;
      }
    }
  }
`;
