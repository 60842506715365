import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import LinearProgress from "@material-ui/core/LinearProgress";
import CircularProgress from "@material-ui/core/CircularProgress";
import ProductDetailEditDropdown from "./ProductDetailEditDropdown";
import ProductDetailRecategorization from "./ProductDetailRecategorization";
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

const DetailAttributes = ({
  attributes = [],
  L1L2Categories,
  confidence_score_percentage,
  productId,
  reset,
  loading,
  subCategoryRef,
  subCategoryFocus,
  onChangeSelectAttribute,
  attributeFiltersUpdate,
  attributeFilters = [],
  showWarning = false,
  attributeFiltersToShow = [],
}) => {
  const [showModal, setShowModal] = React.useState({
    status: false,
    callback: null,
  });
  //Show warning if unsaved data found
  const handleFilterClick = (i) => {
    if (showWarning) {
      setShowModal({ status: true, callback: () => handleChange(i) });
    } else handleChange(i);
  };
  //Change handler for selecting/deselecting filters on CNN,TXT,OCR
  const handleChange = (i) => {
    let arr = attributeFilters.slice();
    if (arr.length === 0) {
      arr.push(i);
    } else {
      if (arr.includes(i))
        arr.splice(
          arr.findIndex((o) => o === i),
          1
        );
      else arr.push(i);
    }
    attributeFiltersUpdate(arr);
  };

  return (
    <React.Fragment>
      <Box
        display="flex"
        alignItems="baseline"
        style={{
          marginBottom: "15px",
          justifyContent: "space-between",
          width: "80%",
          maxWidth: "530px",
          minWidth: "430px",
        }}
      >
        <Typography
          variant="h4"
          style={{
            fontSize: "24px",
            fontWeight: "500",
            color: "rgba(0,0,0)",
            paddingBottom: "0px",
            alignSelf: "center",
            marginRight: "55px",
          }}
        >
          Attributes
        </Typography>
        {attributeFiltersToShow.length > 1 && (
          <MultiSelector
            handleFilterClick={handleFilterClick}
            attributeFilters={attributeFilters}
            attributeFiltersToShow={attributeFiltersToShow}
          />
        )}
        {/* <Box ml={1}>
          <CircularProgressWithLabel style={{marginLeft: '20px'}} value={confidence_score_percentage} />
          <Typography variant="button" display="inline">
            {confidence_score_percentage}%
          </Typography>
        </Box> */}
      </Box>

      <div
        style={{ width: "80%", maxWidth: "550px", minWidth: "450px",overflow: 'auto', height: '450px', paddingBottom: '15px' }}
        id="productAttributes"
      >
        {loading && <LinearProgress variant="indeterminate" />}
        <ProductDetailRecategorization
          key={productId}
          L1L2Categories={L1L2Categories}
          onChangeSelectAttribute={onChangeSelectAttribute}
          reset={reset}
          subCategoryRef={subCategoryRef}
          subCategoryFocus={subCategoryFocus}
        />
        {attributes.map((attribute, i) => (
          <Attribute key={attribute.id} id={attribute.label}>
            <label htmlFor="">{attribute.label}</label>
            <Box position="relative">
              <ProductDetailEditDropdown
                key={attribute.id}
                attribute={attribute}
                index={i}
                onChangeSelectAttribute={onChangeSelectAttribute}
                reset={reset}
              />
              {/* <Box position="absolute" right={-40} top={4}>
                <Typography variant="overline" display="inline">
                  {attribute.mapping_confidence}%
                </Typography>
              </Box> */}
            </Box>
          </Attribute>
        ))}
      </div>
      {showModal.status && (
        <ConfirmPrompt
          confirmCallback={(val) => {
            if (val) {
              showModal.callback();
            }
            setShowModal({
              status: false,
              callback: null,
            });
          }}
        />
      )}
    </React.Fragment>
  );
};

DetailAttributes.propTypes = {
  attributes: PropTypes.array,
};

export default DetailAttributes;

const Attribute = styled.div`
  display: flex;
  // padding: 0 48px;
  margin: 10px 0;
  padding-right: 10px;
  align-items: center;
  justify-content: "space-between";

  label {
    font-size: 14px;
    font-weight: 500;
    padding: 10px 10px 10px 0px;
    flex: 1 1 auto;
    color: rgba(0, 0, 0, 0.7);
  }
  .product-detail-attribute-select {
    font-size: 14px;
    flex: 1 1 auto;
    width: 280px;
    color: rgba(0, 0, 0, 0.8);
  }
`;

const CircularProgressWithLabel = (props) => (
  <Box position="relative" display="inline-flex">
    <CircularProgress variant="determinate" {...props} />
    <Box
      top={2}
      left={20}
      bottom={0}
      right={0}
      position="absolute"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Typography
        variant="caption"
        component="div"
        color="textSecondary"
      >{`${Math.round(props.value)}%`}</Typography>
    </Box>
  </Box>
);

//Filter Selector Buttons - Multi Select
const MultiSelector = ({
  handleFilterClick,
  attributeFilters,
  attributeFiltersToShow,
}) => {
  return (
    <PillContainer>
      {attributeFiltersToShow.map((name, index) => (
        <>
          <input
            key={name}
            type="checkbox"
            checked={attributeFilters.findIndex((o) => o === name) !== -1}
            id={"option-" + index}
            name="selector"
            value={name}
            onChange={() => handleFilterClick(name)}
          />
          <label
            id={"option-" + index + "-label"}
            className={"selector option-" + index}
            htmlFor={"option-" + index}
          >
            {name === "DESCRIPTION" ? "TXT" : name}
          </label>
        </>
      ))}
    </PillContainer>
  );
};

const PillContainer = styled.div`
  margin: 20px 0 20px 0;
  & input[type="checkbox"] {
    opacity: 0;
    position: fixed;
    width: 0;
  }
  display: flex;
  flex-direction: row;
  height: 2rem;
  width: 270px;
  box-sizing: border-box;

  .selector {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0 5px 0 10px;
    color: #4f677b;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    cursor: pointer;
    background: #fff;
    border-radius: 5px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    &:hover {
      background: rgba(10, 100, 100, 0.1);
    }
  }
  .option-0 {
    margin-left: 0;
  }
  input[type="checkbox"]:checked + label {
    font-weight: 600;
    border: 1px solid lightgray;
    box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset,
      rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
  }
`;

const ConfirmPrompt = ({
  showModal = true,
  setConfirm = () => null,
  confirmCallback = () => null,
}) => {
  const allowTransition = (e) => {
    e.stopPropagation();
    setConfirm(false);
    confirmCallback(true);
  };

  const blockTransition = (e) => {
    e.stopPropagation();
    setConfirm(false);
    confirmCallback(false);
  };

  return (
    <Dialog
      open={showModal}
      onClose={blockTransition}
      aria-labelledby="confirmChanges"
      id={"confirmChanges"}
    >
      <DialogTitle id="confirmActionTitle">{"Leave Page"}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {"Are you sure you want to leave this page without saving changes?"}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          id="confirmActionNo"
          onClick={blockTransition}
          color="primary"
        >
          No
        </Button>
        <Button
          id="confirmActionYes"
          onClick={allowTransition}
          color="primary"
          autoFocus
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};
