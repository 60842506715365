import React from 'react'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded'
import CircularProgress from '@material-ui/core/CircularProgress'
import { blue } from '@material-ui/core/colors'
import Fab from '@material-ui/core/Fab'
import clsx from 'clsx'
import { connect } from 'react-redux'
import { downloadReport } from '../../../actions/dashboardActions'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center'
    },
    wrapper: {
        margin: theme.spacing(0, 1),
        position: 'relative',
        width: 36,
        height: 36
    },
    buttonSuccess: {
        backgroundColor: blue[400],
        '&:hover': {
            backgroundColor: blue[700]
        }
    },
    fapButton: {
        width: 36,
        height: 36,
        backgroundColor: blue[400],
        '&:hover': {
            backgroundColor: blue[600]
        }
    },
    fabProgress: {
        color: blue[700],
        position: 'absolute',
        top: -3,
        left: -3,
        zIndex: 1
    },
    buttonProgress: {
        color: blue[100],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    }
}))

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
        marginTop: 0
    },
    list: {
        fontFamily: '"Roboto", sans-serif',
        fontSize: 13,
        fontWeight: 400,
        padding: 0,
        '& > :first-child': {
            color: 'grey'
        },
        '& > :first-child:hover': {
            backgroundColor: 'transparent',
            cursor: 'default'
        }
    }
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
        }}
        {...props}
    />
))

const StyledMenuItem = withStyles((theme) => ({
    root: {
        lineHeight: 'unset',
        fontSize: 12,
        justifyContent: 'center'
    }
}))(MenuItem)

const ExportButton = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [isLoading, setisLoading] = React.useState(false)
    const classes = useStyles()

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = (params) => {
        console.log('handle close called')
        setAnchorEl(null)
    }

    const handleLoading = (params) => {
        setisLoading(params)
    }

    const onExport = (params) => {
        setAnchorEl(null)
        handleLoading(true)
        props.downloadReport(props.rowId, params, handleLoading)
    }

    const buttonClassname = clsx({
        [classes.fapButton]: true,
        [classes.buttonSuccess]: props.isDownloading && props.id === props.rowId
    })


    return (
        <>
            <div className={classes.wrapper}>
                <Fab
                    aria-label="save"
                    className={buttonClassname}
                    //onClick={handleButtonClick}
                    id={'download'+props.rowId}
                    color={props.color}
                    disabled={props.disabled}
                    onClick={handleClick}
                >
                    <GetAppRoundedIcon fontSize="small" />
                </Fab>
                {isLoading && (
                    <CircularProgress
                        size={42}
                        className={classes.fabProgress}
                    />
                )}
            </div>
            <StyledMenu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <StyledMenuItem key="file">Save As</StyledMenuItem>
                <StyledMenuItem key="csv" onClick={() => onExport('csv')}>
                    CSV
                </StyledMenuItem>
                <StyledMenuItem key="json" onClick={() => onExport('json')}>
                    JSON
                </StyledMenuItem>
            </StyledMenu>
        </>
    )
}

const mapStateToProps = (state) => ({
    isDownloading: state.dashboardReducer.isDownloading,
    id: state.dashboardReducer.id
})

export default connect(mapStateToProps, {
    downloadReport
})(ExportButton)
