import React, { useEffect, useReducer, useCallback } from "react";
import { ProductViewListWrapper } from "./CategoryCommons";
import ProductViewGrid from "./ProductViewGrid";
import ProductViewList from "./ProductViewList";
import { Box } from "@material-ui/core";
import { getValueForKey } from "../../../components/helper";
import L1L2Popup from "./L1L2Popup";
import DeleteModal from "./DeleteModal";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import ShowToast from "../../../../../../../commonComponents/toast";

const initialState = {
  open: false,
  anchorEl: null,
  category: null,
  subCategory: null,
  activeStep: 0,
  showWarning: {
    status: false,
    header: "",
    message: "",
    callback: null,
  },
  productDetails: {},
  smartCopy: { prev: null, curr: null, count: 0 },
};

const reducer = (state, action = {}) => {
  switch (action.type) {
    case "SET_MODAL":
      return {
        ...state,
        anchorEl: action.payload.anchorEl,
        open: action.payload.open,
        productDetails: action.payload.productDetails,
      };

    case "SET_WARNING":
      return {
        ...state,
        showWarning: action.payload,
      };
    case "SET_SMART_COPY_COUNT":
      return {
        ...state,
        smartCopy: { ...state.smartCopy, count: state.smartCopy.count + 1 },
      };
    case "SET_SMART_COPY":
      return {
        ...state,
        smartCopy: {
          ...state.smartCopy,
          prev: { ...action.payload.prev },
          curr: { ...action.payload.curr },
        },
      };
    case "RESET_SMART_COPY":
      return {
        ...state,
        smartCopy: { prev: null, curr: null, count: 0 },
      };
    default:
      return state;
  }
};

const Main = (props) => {

  const [state, dispatch] = useReducer(reducer, initialState);

  const location = useLocation();
  useEffect(() => {
    const item = document.getElementById(`exCatImage${location.state}`);
    if (item) {
      item.scrollIntoView();
    }
  }, [props.response?.data?.length]);

  useEffect(() => {
    if (props.isSmartCopy && state.smartCopy.count) {
      ShowToast(
        <>
          {state.smartCopy.count + 1} attributes changed from{" "}
          {state.smartCopy.prev?.value} to {state.smartCopy.curr?.value}
        </>,
        "info",
        "top-right"
      );
    }
    dispatch({ type: "RESET_SMART_COPY" });
  }, [state.smartCopy.count, props.isSmartCopy, props.response]);

  const handleModal = useCallback((payload) => {
    dispatch({ type: "SET_MODAL", payload });
  },[]);

  const handleWarning = useCallback((payload) => {
    dispatch({ type: "SET_WARNING", payload });
  },[]);

  const handleSmartCopyCount = useCallback((payload) => {
    dispatch({ type: "SET_SMART_COPY_COUNT", payload });
  },[]);
  
  const handleSmartCopyValue = useCallback((payload) => {
    dispatch({ type: "SET_SMART_COPY", payload });
  },[]);

  return (
    <Box position="relative" width="100%" my={1}>
      {getValueForKey("list") === "true" ? (
        <ProductViewListWrapper $isfilterOpen={props.filtersOpen}>
          <ProductViewList
            {...props}
            handleWarning={handleWarning}
            handleModal={handleModal}
            handleSmartCopyCount={handleSmartCopyCount}
            handleSmartCopyValue={handleSmartCopyValue}
            smartCopy={state.smartCopy}
          />
        </ProductViewListWrapper>
      ) : (
        <ProductViewGrid
          {...props}
          handleModal={handleModal}
          handleWarning={handleWarning}
          handleSmartCopyCount={handleSmartCopyCount}
          handleSmartCopyValue={handleSmartCopyValue}
          smartCopy={state.smartCopy}
        />
      )}
      {state.open && (
        <L1L2Popup
          state={state}
          // L1_L2_categories={props.attributes[1].tenant_attribute_value_list}
          L1_L2_categories={
            props.attributes[
              Object.keys(props.attributes).find(
                (o) => props.attributes[o].name === "Category"
              )
            ].tenant_attribute_value_list
          }
          handleRecategorization={props.saveEdits}
          handleModal={handleModal}
        />
      )}
      {state.showWarning.status && (
        <DeleteModal
          showWarning={state.showWarning}
          handleWarning={handleWarning}
        />
      )}
    </Box>
  );
};

const mapStateToProps = (state) => ({
  attributes: state.attributeSmartReducer.attributes,
});

export default connect(mapStateToProps)(Main);
