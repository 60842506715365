import React from 'react'
import { getValueForKey } from "../../../components/helper";
import { Box } from "@material-ui/core";
import ProductActions from "./ProductActions";
import CategoryPagination from "./CategoryPagination";
const Footer = (props) => {
    return (
        props.count > 0 && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            mt={2}
          >
            <CategoryPagination count={props.count} showActions />
    
            <ProductActions
              show={props.singleEdit || getValueForKey("list") === "true"}
              saveEdits={props.saveEdits}
              handleConfidence={props.handleConfidence}
              handleResetPageSize={props.handleResetPageSize}
            />
          </Box>
        )
      )
}

export default Footer;
