import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TablePagination } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { getValueForKey, handleReplace } from '../../../components/helper';

const useStyles = makeStyles(() => ({
  root: {
    display: 'inline-flex',
  },
  toolbar: {
    paddingLeft: 8,
    minHeight: 'auto',
  },
  caption: {
    fontSize: '0.75rem',
    fontFamily: `Roboto, Helvetica, Arial, sans-serif`,
    fontWeight: 400,
    lineHeight: 1.66,
    letterSpacing: '0.03333em',
    // marginBottom: 5,
  },
  showActions: {
    display: 'block',
  },
  hideActions: {
    display: 'none',
  },
}));

export default function CategoryPagination({ count, showActions = false }) {
  const classes = useStyles();
  const history = useHistory();

  if(count) {
    return (
      <TablePagination
        variant="caption"
        component="div"
        count={count}
        page={(Number(getValueForKey('page')) || 1) - 1}
        onChangePage={(_, page) => handleReplace('page', page + 1, history)}
        rowsPerPage={Number(getValueForKey('image_count')) || 40}
        rowsPerPageOptions={[]}
        classes={{
          root: classes.root,
          toolbar: classes.toolbar,
          actions: showActions ? classes.showActions : classes.hideActions,
          caption: !showActions ? classes.caption : '',
        }}
        backIconButtonProps={{id:'categoryPrevious'}}
        nextIconButtonProps={{id:'categoryNext'}}
      />
    )
  }

  return (
    <></>
  )

}
