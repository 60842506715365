import React, { useState, memo } from "react";
import ReactImageMagnify from "react-image-magnify";
import { RotateCw } from "react-feather";

const ImageMagnify = ({ imageUrl, imageUrlHighReso, index, listView }) => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <>
      {isLoading && (
        <span className="image-loading">
          <RotateCw />
        </span>
      )}
      <ImageMagnifier
        imageUrl={imageUrl}
        setIsLoading={setIsLoading}
        imageUrlHighReso={imageUrlHighReso}
        index={index}
        listView={listView}
      />
    </>
  );
};

export default ImageMagnify;

const ImageMagnifier = memo(
  ({ imageUrl, setIsLoading, imageUrlHighReso, index, listView }) => {
    return (
      <ReactImageMagnify
        {...{
          smallImage: {
            isFluidWidth: true,
            src: imageUrl,
            onLoad: () => setIsLoading(false),
          },
          largeImage: {
            src: imageUrlHighReso,
            width: 1200,
            height: 1800,
          },
          enlargedImageContainerDimensions: {
            width: listView ? 220 : 270,
            height: listView ? 340 : 390,
          },
          enlargedImagePosition: "beside",
          enlargedImageStyle: { zIndex: "999", position: "absolute" },
          enlargedImageContainerStyle: {
            position: "absolute",
            top: "-100px",
            borderRadius: "3px",
            border: "1px solid lightgray",
            backgroundColor: "white",
          },
          enlargedImagePortalId: `portalDiv${index}`,
        }}
      />
    );
  }
);
