// Upload action types
export const SET_BATCH_NAME = "SET_BATCH_NAME";
export const SET_FILE = "SET_FILE";
export const SET_PROGRESS = "SET_PROGRESS";
export const SET_UPLOAD_SUCCESS = "SET_UPLOAD_SUCCESS";
export const CLEAR_UPLOAD = "CLEAR_UPLOAD";

//Dashboard action types
export const RECEIVE_BATCH_DATA = "RECEIVE_BATCH_DATA";
export const SET_PAGE = "SET_PAGE";
export const SHOW_MODAL = "SHOW_MODAL";
export const DELETE_INIT = "DELETE_INIT";
export const DELETE_ERROR = "DELETE_ERROR";
export const DELETE_SUCCESS = "DELETE_SUCCESS";
export const DASHBOARD_INIT = "DASHBOARD_INIT";
export const DASHBOARD_SUCCESS = "DASHBOARD_SUCCESS";
export const DASHBOARD_ERROR = "DASHBOARD_ERROR";
export const DOWNLOAD_REPORT_INIT = "DOWNLOAD_REPORT_INIT";
export const DOWNLOAD_REPORT_SUCCESS = "DOWNLOAD_REPORT_SUCCESS";
export const DOWNLOAD_REPORT_ERROR = "DOWNLOAD_REPORT_ERROR";
export const DOWNLOAD_ERROR_REPORT_INIT = "DOWNLOAD_ERROR_REPORT_INIT";
export const DOWNLOAD_ERROR_REPORT_SUCCESS = "DOWNLOAD_ERROR_REPORT_SUCCESS";
export const DOWNLOAD_ERROR_REPORT_ERROR = "DOWNLOAD_ERROR_REPORT_ERROR";
export const DOWNLOAD_FILE_INIT = "DOWNLOAD_FILE_INIT";
export const DOWNLOAD_FILE_SUCCESS = "DOWNLOAD_FILE_SUCCESS";
export const DOWNLOAD_FILE_ERROR = "DOWNLOAD_FILE_ERROR";

export const SHOW_DELETE_MODAL = "SHOW_DELETE_MODAL";
export const SHOW_LOADER = "SHOW_LOADER";
export const SHOW_LOADER_CLOSE_MODAL = "SHOW_LOADER_CLOSE_MODAL";

//Explore Category Page action types
export const GET_CATEGORY_DATA = "GET_CATEGORY_DATA";
export const GET_FILTER_DATA = "GET_FILTER_DATA";
export const TOGGLE_LOADER = "TOGGLE_LOADER";
export const FILTER_LOADER = "FILTER_LOADER";

//AttributeSmart Global action types
export const ATTRIBUTES_INIT = "ATTRIBUTES_INIT";
export const ATTRIBUTES_SUCCESS = "ATTRIBUTES_SUCCESS";
export const ATTRIBUTES_ERROR = "ATTRIBUTES_ERROR";

//Set Dashboard Search and Sort Query params
export const SET_QUERY_PARAMS = "SET_QUERY_PARAMS";

//Hide Dashboard Table Status Message
export const HIDE_STATUS = "HIDE_STATUS";
