import React, { memo } from "react";
import styled from "styled-components";
import clsx from "clsx";
import { useHistory } from "react-router";
import L1L2Button from "./L1L2Button";
import ImageMagnify from "./ImageMagnify";
import SelectDropdown from "./SelectDropdown";
import useIntersectionObserver from "../../../../../hooks/useIntersectionObserver";

const tagColor = ["#44677be0", "#00A0B4"];

const ImageCardWrapper = ({
  index,
  productDetails,
  isSmartCopy,
  imageUrl,
  imageUrlHighReso,
  dropdownData = [],
  selected,
  handleRecategorization,
  handleModal,
  handleWarning,
  listView = false,
  handleSmartCopyCount,
  handleSmartCopyValue,
  smartCopy,
}) => {
  const history = useHistory();
  const ref = React.useRef();
  const [isVisible, setIsVisible] = React.useState(false);

  useIntersectionObserver({
    target: ref,
    onIntersect: ([{ isIntersecting }], observerElement) => {
      if (isIntersecting) {
        setIsVisible(true);
        observerElement.unobserve(ref.current);
      }
    },
  });

  const handlePath = () => {
    let pathArr = history.location.pathname;
    let searchArr = history.location.search;
    history.push({
      pathname: `${pathArr}/${productDetails.product_id}`,
      search: searchArr,
      state: productDetails.product_id,
    });
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
      id={"exCatImg" + index}
      key={index}
    >
      <ParentImgDiv
        id={`exCatImage${productDetails.product_id}`}
        tag={productDetails.product_display_status?.text}
        bgcolor={tagColor[productDetails.product_display_status?.color]}
        inset={listView}
      >
        <div id={`portalDiv${index}`} className="portalDiv"></div>
        <div
          className={clsx(
            "figure",
            "tag",
            "tag-edited",
            productDetails.product_display_status?.text
          )}
        >
          <ImgDiv
            bgcolor={listView}
            onClick={(e) => {
              e.stopPropagation();
              handlePath();
            }}
            ref={ref}
          >
            {isVisible && (
              <>
                <L1L2Button
                  productDetails={productDetails}
                  handleRecategorization={handleRecategorization}
                  handleModal={handleModal}
                  handleWarning={handleWarning}
                />
                <ImageMagnify
                  imageUrl={imageUrl}
                  imageUrlHighReso={imageUrlHighReso}
                  index={index}
                  listView={listView}
                />
              </>
            )}
          </ImgDiv>
        </div>
      </ParentImgDiv>
      {!listView && dropdownData && dropdownData?.length ? (
        <SelectDropdown
          dropdownData={dropdownData}
          selected={selected}
          productDetails={productDetails}
          isSmartCopy={isSmartCopy}
          handleSmartCopyCount={handleSmartCopyCount}
          handleSmartCopyValue={handleSmartCopyValue}
          smartCopy={smartCopy}
        />
      ) : null}
    </div>
  );
};

export default memo(ImageCardWrapper);

const ParentImgDiv = styled.div`
  height: 400px;
  width: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
  .portalDiv {
    position: relative;
    top: ${(props) => (!props.inset ? "-100px" : "-65px")};
    left: ${(props) => (!props.inset ? "-30px" : "-10px")};
    border-radius: 3px;
    border: 1px solid lightgray;
  }

  .figure {
    margin: 0;
    font-size: 13px;
  }
  .figure.tag {
    position: relative;
  }
  .figure.tag::before {
    position: absolute;
    top: 30px;
    right: 0;
    display: block;
    color: white;
    font-weight: bold;
    opacity: 0.8;
  }
  .figure.tag-edited::before {
    content: "${(props) => props.tag}";
    background: ${(props) => props.bgcolor};
    padding: 2px 8px;
    z-index: 1;
    border-radius: 6px 0 0 6px;
  }
`;

const ImgDiv = styled.div`
  position: relative;
  background: ${(props) => (!props.bgcolor ? "#d3d3d347" : "")};
  height: 350px;
  width: 200px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 2px;
  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 20%);
  .magnifier {
    border: 2px solid red;
    div {
      div:first-child {
        border: 2px solid red;
        heigth: 100px !important;
      }
    }
  }
  .image-loading {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    color: white;
    background: #0000007a;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    svg {
      animation-name: spin;
      animation-duration: 1500ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
