import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React from "react";
import { getValueForKey } from "../../../components/helper";

const DeleteModal = ({ showWarning, handleWarning }) => {
  return (
    showWarning.status && (
      <ConfirmPrompt
        title={showWarning.header}
        message={showWarning.message}
        confirmCallback={(val, _, current) => {
          if (val) {
            showWarning.callback(current);
          }
          handleWarning({
            status: false,
            header: "",
            message: "",
            callback: null,
          });
        }}
      />
    )
  );
};

export default DeleteModal;

const ConfirmPrompt = ({
  showModal = true,
  setConfirm = () => null,
  confirmCallback = () => null,
  message = "Are you sure you want to leave this page without saving changes ?",
  title = "Leave Page",
  showFirstBtn = false,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const allowTransition = (e, firstBtn = false) => {
    e.stopPropagation();
    setConfirm(false);

    confirmCallback(true, false, firstBtn);
  };

  const blockTransition = (e) => {
    e.stopPropagation();
    setConfirm(false);
    confirmCallback(false);
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={showModal}
      onClose={blockTransition}
      aria-labelledby="routePromptTitle"
      id={"routePrompt"}
    >
      <DialogTitle id="routePromptTitle">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      {getValueForKey("type") === "true" ? (
        <DialogActionVariantView
          allowTransition={allowTransition}
          blockTransition={blockTransition}
        />
      ) : (
        <DialogActionProductView
          allowTransition={allowTransition}
          blockTransition={blockTransition}
        />
      )}
    </Dialog>
  );
};

const DialogActionProductView = ({ allowTransition, blockTransition }) => {
  return (
    <DialogActions>
      <Button
        id="routePromptYes"
        onClick={allowTransition}
        color="primary"
        autoFocus
      >
        Yes
      </Button>

      <Button
        autoFocus
        id="routePromptNo"
        onClick={blockTransition}
        color="primary"
      >
        No
      </Button>
    </DialogActions>
  );
};

const DialogActionVariantView = ({ allowTransition, blockTransition }) => {
  return (
    <DialogActions>
      <Button
        id="routePromptYes"
        onClick={(e) => allowTransition(e, true)}
        color="primary"
        autoFocus
      >
        This One
      </Button>
      <Button
        id="routePromptYes"
        onClick={allowTransition}
        color="primary"
        autoFocus
      >
        All
      </Button>
      <Button
        autoFocus
        id="routePromptNo"
        onClick={blockTransition}
        color="primary"
      >
        Cancel
      </Button>
    </DialogActions>
  );
};
