import React, { useEffect, useRef } from 'react'
import {
    useTable,
    usePagination,
    useSortBy,
} from 'react-table'
import TablePagination from '@material-ui/core/TablePagination'
import LinearProgress from '@material-ui/core/LinearProgress'
import IconButton from '@material-ui/core/IconButton'
import RefreshRoundedIcon from '@material-ui/icons/RefreshRounded'
import Typography from '@material-ui/core/Typography'
import { Inbox } from 'react-feather'
import Box from '@material-ui/core/Box'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Search from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import { setQueryParams, autoRefreshDashboardData } from '../../../actions/dashboardActions'
import { connect } from 'react-redux';

//Defining a hook for periodic execution of a function using setInterval
const useInterval = (callback, delay) => {
    const savedCallback = useRef();
    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);
    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        //Cleanup
        return () => clearInterval(id);
      }
    }, [delay]);
}

const useStyles = makeStyles((theme) => ({
    inputRoot: {
        fontSize: 14,
        // borderRadius: '6px'
    },
    labelRoot: {
        fontSize: 13,
        // color: "red",
        "&$labelFocused": {
          color: "#3f51b5"
        }
    },
    adornedEnd:{
        paddingRight: '4px !important'
    },
    labelFocused: {},
    inputAdornmentRoot: {
        marginRight: '0 !important',
        paddingTop: '3px',
        '& button': {
            outline:'none',
            border:'none',
            textTransform:'none',
            backgroundColor: 'white'
        }
    },
    searchButton: {
          '&:hover' : {
              transform: 'scale(1.1)'
          },
          '&:active': {
              opacity: '0.5'
          }
    }
}))

const DashboardTable = ({
    columns,
    data,
    fetchData,
    pageCount: controlledPageCount,
    headerOptions,
    totalCount,
    isLoading,
    status,
    pageNo,
    handlePageNo,
    setQueryParams,
    autoRefreshDashboardData,
    payload
}) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        setHiddenColumns,
        state:{sortBy}
    } = useTable(
        {
            columns,
            data,
            initialState: { pageNo: 0 },
            manualPagination: true,
            pageCount: controlledPageCount,
            autoResetPage: false,
            manualSortBy: true
        },
        useSortBy,
        usePagination
    )

    //ignore sonam warning for below, state can be used in future to configure records per page number
    const [rowsPerPage, setRowsPerPage] = React.useState(10)
    const classes = useStyles();
    const [searchQ, setSearchQ] = React.useState('')
    
    const constructPayload = ()=>{
        let sortQ = '';
        if(sortBy.length>0) {
            // sortQ = sortBy[0].desc?'-'+sortBy[0].id:'+'+sortBy[0].id
            sortQ = sortBy[0].desc?'-'+sortBy[0].id:sortBy[0].id
        }
        setQueryParams({searchQ:searchQ,sortQ:sortQ})
        fetchData(pageNo,'false',searchQ,sortQ)
    }

    //Auto-refresh custom hook
    useInterval(() => {
        let {showModal, showDeleteModal, showLoader, isDeleting, isDownloading, isDownloadingError, isDownloadingFile} = payload;
        let sortQ = '';
        if(sortBy.length>0) sortQ = sortBy[0].desc?'-'+sortBy[0].id:sortBy[0].id;
        if(!(showModal || showDeleteModal || showLoader || isDeleting || isDownloading || isDownloadingError || isDownloadingFile)) {
            // fetchData(pageNo,'false',searchQ,sortQ)
            autoRefreshDashboardData(pageNo,'false',searchQ,sortQ)
        }
    }, 120000);
    
    //Call to fetch API for sorting/searching/pagination
    useEffect(() => {
        constructPayload()
    }, [pageNo, rowsPerPage, sortBy, searchQ])

    //Push back to first page while API sorting
    useEffect(() => {
        handlePageNo(0)
    }, [sortBy])

    //Reiterate through columns for filtering out isVisible: false
    useEffect(() => {
        setHiddenColumns(
          columns.filter(column => !column.isVisible).map(column => column.accessor)
        );
    }, [setHiddenColumns, columns]);

    const handleChangePage = (event, newPage) => {
        handlePageNo(newPage)
    }

    return (
        <section className="md-ui component-data-table">
            <header className="main-table-header">
                <h1 className="table-header--title" id='dashboardTitle'>Dashboard</h1>
                <div className="table-header--action ">
                    <>
                        <IconButton
                            color="primary"
                            onClick={constructPayload}
                            id='dashboardRefreshBtn'
                        >
                            <RefreshRoundedIcon fontSize="small" />
                        </IconButton>
                    </>
                </div>
                <div className='table-header--icons'>
                    {status}
                </div>
            </header>
            <form onSubmit={(e)=>{
                handlePageNo(0)
                e.preventDefault()
                let inputVal = document.getElementById('dashboardSearchInput').value
                setSearchQ(inputVal)
            }}>
                <TextField
                    id="dashboardSearchInput"
                    label="Search Batch"
                    margin="dense"
                    variant="outlined"
                    style={{margin:' 0px 0px 10px 24px',fontSize:'12px',}}
                    InputLabelProps={{
                        classes: {
                            root: classes.labelRoot,
                            focused: classes.labelFocused
                        }
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start" className={classes.inputAdornmentRoot}>

                                { document.getElementById('dashboardSearchInput')?.value? 
                                        <CancelIcon id='dashboardClearSearchBtn' className={classes.searchButton} style={{color:"gray", align:"center", cursor:'pointer'}} onClick={(e)=>{
                                            document.getElementById('dashboardSearchInput').value = '';
                                            handlePageNo(0)
                                            setSearchQ('')
                                        }}/>
                                     : null
                                }   
                                <button type='submit' value='Submit' id='dashboard-search-btn' className={classes.searchButton}>
                                    <Search id='dashboardSearchBtn' style={{color:searchQ?"#3f51b5e5":"gray", align:"center"}}/>
                                </button>
                            </InputAdornment>
                        ),
                        classes: { root: classes.inputRoot, adornedEnd: classes.adornedEnd }
                    }}
                />
            </form>
            <div className="main-table-wrapper">
                <table
                    className="main-table-content"
                    {...getTableProps()}
                    id="dashboardBatchTable"
                >
                    <thead className="data-table-header">
                        {headerGroups.map((headerGroup) => (
                            <tr
                                {...headerGroup.getHeaderGroupProps()}
                                className="data-table-row"
                            >
                                {headerGroup.headers.map((column, i) => (
                                    <td
                                        key={i}
                                        id={`dashboardBatchTable${i}`}
                                        {...column.getHeaderProps(
                                            column.getSortByToggleProps()
                                        )}
                                        className="table-datacell datatype-string"
                                    >
                                        <div>
                                            <span
                                                style={{
                                                    position: 'relative'
                                                }}
                                            >
                                                {column.render('Header')}
                                                <SortIcon column={column} />
                                            </span>
                                        </div>
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <TableProgress show={isLoading} colSpan={columns.length} />

                    {data.length > 0 ? (
                        <tbody
                            {...getTableBodyProps()}
                            className="data-table-content"
                        >
                            {page.map((row, i) => {
                                prepareRow(row)
                                return (
                                    <tr
                                        {...row.getRowProps()}
                                        id={`dashboardBatchTableRowIndex${i}`}
                                        key={`batch-summary-index-${i}`}
                                        className="data-table-row"
                                    >
                                        {row.cells.map((cell, cellKey) => (
                                            <td
                                                key={cellKey}
                                                {...cell.getCellProps()}
                                                className="table-datacell datatype-numeric"
                                            >
                                                {cell.render('Cell')}
                                            </td>
                                        ))}
                                    </tr>
                                )
                            })}
                        </tbody>
                    ) : (
                        <tbody
                            {...getTableBodyProps()}
                            className="data-table-content"
                        >
                            <NoData
                                fetchData={fetchData}
                                pageIndex={pageNo}
                                colSpan={columns.length}
                            />
                        </tbody>
                    )}
                </table>
            </div>
            <footer className="main-table-footer">
                <TablePagination
                    component="div"
                    count={totalCount}
                    page={pageNo}
                    onChangePage={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[10]}
                    id='pagination'
                    backIconButtonProps={{id:'dashboardPrevious'}}
                    nextIconButtonProps={{id:'dashboardNext'}}
                />
            </footer>
        </section>
    )
}
const mapStateToProps = (state) => ({
  payload: state.dashboardReducer,
});
export default connect(mapStateToProps,{
    setQueryParams,
    autoRefreshDashboardData
})(DashboardTable)

const TableProgress = ({ show, colSpan }) => {
    if (show) {
        return (
            <thead>
                <tr style={{ borderBottom: 'none' }}>
                    <td
                        colSpan={colSpan}
                        style={{ padding: 0, position: 'relative' }}
                    >
                        <div
                            style={{
                                position: 'absolute',
                                width: '100%',
                                top: '-5px'
                            }}
                        >
                            <LinearProgress variant="indeterminate" />
                        </div>
                    </td>
                </tr>
            </thead>
        )
    }

    return (<></>)
}



const SortIcon = ({column}) => {
    if(column.isSorted) {
        if(column.isSortedDesc) {
            return (
                <ArrowDownwardIcon
                fontSize="small"
                style={{
                    position: 'absolute',
                    bottom:0
                }}
            />
            )
        } else {
            return (
                <ArrowUpwardIcon
            fontSize="small"
            style={{
                position: 'absolute',
                bottom:0
            }}
        />
            )
        }
    }
    return (
        ''
    )
}

const NoData = ({ fetchData, pageIndex, colSpan }) => {
    return (
        <tr className="data-table-row">
            <td className="table-datacell datatype-info" colSpan={colSpan}>
                <span>
                    <Inbox size={64} />
                    <div>
                        <Typography variant="caption" gutterBottom>
                            {/* It looks like you do not have any
              batch. Upload some batch. */}
                            No Data.
                        </Typography>
                    </div>
                </span>

                <Box my={3}>
                    <Typography variant="caption" display="block" gutterBottom>
                        It looks like you do not have any Batch. Upload Batch.
                        <br />
                        Once you do, this page will refresh.
                    </Typography>

                    <Typography
                        variant="overline"
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        Please refresh
                        <RefreshRoundedIcon
                            onClick={(pageNo) => fetchData(pageNo)}
                            color="primary"
                        />
                        if batch already added.
                    </Typography>
                </Box>
            </td>
        </tr>
    )
}