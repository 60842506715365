import React from "react";
import AttributeDropdown from "./AttributeDropdown";
import DiscreteSlider from "./DiscreteSlider";
import ProductCategoryTabs from "./ProductCategoryTabs";
import ProductCategoryViewToggle from "./ProductCategoryViewToggle";
import ProductListHeader from "./ProductListHeader";
import ShowAllSwitch from "./ShowAllSwitch";
import StatusText from "./StatusText";
import { getValueForKey } from "../../../components/helper";
import { Box } from "@material-ui/core";
import SmartCopySwitch from "./SmartCopySwitch";

const Header = (props) => {
    return (
      <>
        <Box width="100%" my={1}>
          <DiscreteSlider
            imageCount={getValueForKey("image_count")}
            resetPageSize={props.resetPageSize}
          />
          <ProductListHeader
            count={props.count}
            singleEdit={props.singleEdit}
            list={getValueForKey("list") === "true"}
            listattribute={getValueForKey("showall") === "true"}
          />
        </Box>
        <Box position="relative" width="100%" my={1}>
          <Box
            display="flex"
            borderBottom="1px solid #80808061"
            justifyContent="space-between"
          >
            <ProductCategoryTabs />
            <ProductCategoryViewToggle />
          </Box>
          <Box display="flex" mt={2} justifyContent="space-between">
            <StatusText
              list={getValueForKey("list") === "true"}
              singleEdit={props.singleEdit}
              listattribute={getValueForKey("showall") === "true"}
              isVariant={getValueForKey("type") === "true"}
            />
            <SmartCopySwitch
              checked={props.isSmartCopy}
              handleChange={() => props.SetIsSmartCopy(!props.isSmartCopy)}
            />
            {getValueForKey("list") === "true" ? (
              <ShowAllSwitch
                id="ExpCatShowAllSwitch"
                parentId="ExpCatShowAllContainerSwitch"
              />
            ) : (
              <AttributeDropdown dropdownData={props.dropdownData} />
            )}
          </Box>
        </Box>
      </>
    );
  };

export default Header;