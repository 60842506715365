import React, { useState, useEffect } from "react";
import ProductDetailButton from "./ProductDetailButton";
import { Tab, Tabs } from "@material-ui/core";
import axiosInstance from "../../../../../Utils/axios";
import LoadingOverlay from "../../../../../Utils/Loader/loader";
import { update } from "lodash";

const ProductDetailActions = ({
  saveEdits,
  handlePath,
  onChangeSelectAttribute,
  loading,
  initialLoadData,
  updatedLabelData,
  parentDispatch,
}) => {
  const [activeTabValue, setActiveTabValue] = useState(0);
  const [showTab, setShowTab] = useState(false);
  const [desObject, setDesObject] = useState({
    english: "",
    french: "",
    spanish: "",
    portugese: "",
  });

  const handleTabValueChange = (event, value) => {
    setActiveTabValue(value);
  };
  const styledTextArea = {
    width: "70%",
    height: "180px",
  };

  useEffect(() => {
    let desObject = JSON.parse(localStorage.getItem("itemDesObject"));
    if (desObject && desObject[initialLoadData?.id]) {
      setDesObject({
        ...desObject[initialLoadData?.id],
      });
      setShowTab(true);
    } else {
    }
  }, [activeTabValue, updatedLabelData]);

  const initialPayload = () => {
    let payload = [];
    let currentDesc = JSON.parse(localStorage.getItem("itemDesObject"));
    //Adding categories and sub categories to the resultant payload
    for (let i = 0; i < initialLoadData.L1_L2_categories.length; i++) {
      const category = initialLoadData.L1_L2_categories[i];
      if (category.selected === true) {
        payload.push({ Category: category.value });
        console.log("Payload here", payload);
        for (let j = 0; j < category["Sub Category"].length; j++) {
          if (category["Sub Category"][j].selected === true) {
            payload.push({ "Sub Category": category["Sub Category"][j].label });
            break;
          }
        }
        break;
      }
    }
    //Adding rest of the attributes in payload
    for (let i = 0; i < initialLoadData.attributes.length; i++) {
      const attribute = initialLoadData.attributes[i];
      for (let j = 0; j < attribute.values.length; j++) {
        if (attribute.values[j].selected === true) {
          payload.push({ [`${attribute.label}`]: attribute.values[j].label });
          break;
        }
      }
    }
    if (updatedLabelData.length !== 0) {
      console.log("Updated Labels present here", updatedLabelData);
      for (const key in updatedLabelData) {
        let curr_item = updatedLabelData[key];
        let cur_item_attribute = curr_item.tenant_attribute_value;
        for (let j = 0; j < payload.length; j++) {
          let payload_attribute_val = Object.keys(payload[j])[0];
          if (cur_item_attribute === payload_attribute_val) {
            payload[j][payload_attribute_val] = curr_item.new_value;
          }
        }
      }
    }
    const updated_payload = payload.filter((item) => {
      const final_attribute_value = Object.keys(item)[0];
      // console.log(item[final_attribute_value] !== 'No value')
      if (item[final_attribute_value] !== "No value") {
        return item;
      }
    });

    return {
      json_data: updated_payload,
      description:
        currentDesc && currentDesc[initialLoadData?.id]
          ? currentDesc[initialLoadData?.id].english
          : initialLoadData?.input_text[0]?.text,
    };
  };

  const generateDescriptionHandler = async () => {
    parentDispatch({ type: "START_LOADING" });
    let desLocalstorageObject = JSON.parse(
      localStorage.getItem("itemDesObject")
    );
    if (
      desLocalstorageObject &&
      desLocalstorageObject[initialLoadData?.id] &&
      desLocalstorageObject[initialLoadData?.id]?.english
    ) {
      localStorage.setItem(
        "itemDesObject",
        JSON.stringify({
          ...desLocalstorageObject,
          [initialLoadData?.id]: {
            ...desLocalstorageObject[initialLoadData?.id],
            english: localStorage.getItem("currentDesc"),
          },
        })
      );
    }
    console.log("initial Data---->", initialLoadData);
    console.log("new data ----->", updatedLabelData);
    const payload = initialPayload();
    try {
      const result = await axiosInstance.post(
        "/attribute-smart/generate-product-description/",
        payload
      );
      localStorage.setItem("currentDesc", result?.data?.data?.description);
      localStorage.setItem(
        "itemDesObject",
        JSON.stringify({
          ...desLocalstorageObject,
          [initialLoadData?.id]: {
            english: result?.data?.data?.description,
            french: "",
            spanish: "",
            portugese: "",
          },
        })
      );
      console.log("Api Response here", result);
      setDesObject({
        ...desObject,
        english: result?.data?.data?.description,
        french: "",
        spanish: "",
        portugese: "",
      });
      setShowTab(true);
      parentDispatch({ type: "STOP_LOADING" });
    } catch (err) {
      alert("Error Generating Description. Please Try Again");
      setShowTab(false);
      parentDispatch({ type: "STOP_LOADING" });
    }
  };

  const translateDescriptionHandler = async () => {
    parentDispatch({ type: "START_LOADING" });
    setDesObject({
      ...desObject,
      english: localStorage.getItem("currentDesc"),
    });
    const payload = { description: localStorage.getItem("currentDesc") };

    try {
      const result = await axiosInstance.post(
        "/attribute-smart/translate-product-description/",
        payload
      );
      setDesObject({
        english: localStorage.getItem("currentDesc"),
        french: result?.data?.data?.french,
        spanish: result?.data?.data?.spanish,
        portugese: result?.data?.data?.portuguese,
      });
      //
      let desObject = JSON.parse(localStorage.getItem("itemDesObject"));
      if (desObject) {
        localStorage.setItem(
          "itemDesObject",
          JSON.stringify({
            ...desObject,
            [initialLoadData?.id]: {
              english: localStorage.getItem("currentDesc"),
              french: result?.data?.data?.french,
              spanish: result?.data?.data?.spanish,
              portugese: result?.data?.data?.portuguese,
            },
          })
        );
      }
      setShowTab(true);
      parentDispatch({ type: "STOP_LOADING" });
    } catch (err) {
      parentDispatch({ type: "STOP_LOADING" });
      alert("Error Translating Description. Please Try Again");
    }
  };

  const TabPanel = (props) => {
    const { children, value } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== activeTabValue}
        id={`tabpanel-${value}`}
        aria-labelledby={`tab-${value}`}
        style={{ width: "100%", padding: 10 }}
      >
        {value === activeTabValue && <>{children}</>}
      </div>
    );
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          paddingTop: "10px",
          justifyContent: "space-between",
          margin: "0 20px 0 20px",
          padding: "30px 0 40px 0",
        }}
      >
        <div>
          <ProductDetailButton
            label="Generate Description"
            onClickHandler={generateDescriptionHandler}
            type={1}
            loading={loading}
            id="saveBtn"
          />
          &nbsp;&nbsp;
          <ProductDetailButton
            label="Translate"
            onClickHandler={translateDescriptionHandler}
            type={1}
            loading={loading}
            id="cancelBtn"
          />
        </div>
      </div>

      <div style={{ marginLeft: "20px", display: showTab ? "block" : "none" }}>
        Product Description
        <Tabs
          value={activeTabValue}
          onChange={handleTabValueChange}
          aria-label="selected-language-tab"
        >
          <Tab label="English" value={0} />
          <Tab label="French" value={1} />
          <Tab label="Spanish" value={2} />
          <Tab label="Portuguese" value={3} />
        </Tabs>
        <TabPanel value={0}>
          <textarea
            style={styledTextArea}
            onChange={(e) => {
              localStorage.setItem("currentDesc", e.target.value);
              let desLocalstorageObject = JSON.parse(
                localStorage.getItem("itemDesObject")
              );
              if (desLocalstorageObject) {
                localStorage.setItem(
                  "itemDesObject",
                  JSON.stringify({
                    ...desLocalstorageObject,
                    [initialLoadData?.id]: {
                      ...desLocalstorageObject[initialLoadData?.id],
                      english: e.target.value,
                    },
                  })
                );
              }
            }}
            defaultValue={desObject.english}
          />
        </TabPanel>
        <TabPanel value={1}>
          <textarea
            style={styledTextArea}
            defaultValue={desObject.french}
            onChange={(e) => {
              let desObject = JSON.parse(localStorage.getItem("itemDesObject"));
              if (desObject) {
                localStorage.setItem(
                  "itemDesObject",
                  JSON.stringify({
                    ...desObject,
                    [initialLoadData?.id]: {
                      ...desObject[initialLoadData?.id],
                      french: e.target.value,
                    },
                  })
                );
              }
            }}
          />
        </TabPanel>
        <TabPanel value={2}>
          <textarea
            style={styledTextArea}
            defaultValue={desObject.spanish}
            onChange={(e) => {
              let desObject = JSON.parse(localStorage.getItem("itemDesObject"));
              if (desObject) {
                localStorage.setItem(
                  "itemDesObject",
                  JSON.stringify({
                    ...desObject,
                    [initialLoadData?.id]: {
                      ...desObject[initialLoadData?.id],
                      spanish: e.target.value,
                    },
                  })
                );
              }
            }}
          />
        </TabPanel>
        <TabPanel value={3}>
          <textarea
            style={styledTextArea}
            defaultValue={desObject.portugese}
            onChange={(e) => {
              let desObject = JSON.parse(localStorage.getItem("itemDesObject"));
              if (desObject) {
                localStorage.setItem(
                  "itemDesObject",
                  JSON.stringify({
                    ...desObject,
                    [initialLoadData?.id]: {
                      ...desObject[initialLoadData?.id],
                      portugese: e.target.value,
                    },
                  })
                );
              }
            }}
          />
        </TabPanel>
      </div>
      <div
        style={{
          display: "flex",
          paddingTop: "10px",
          justifyContent: "space-between",
          margin: "0 20px 0 20px",
          padding: "30px 0 40px 0",
        }}
      >
        <div>
          <ProductDetailButton
            label="Save & Next"
            onClickHandler={() => saveEdits()}
            type={1}
            loading={loading}
            id="saveBtn"
          />
          &nbsp;&nbsp;
          <ProductDetailButton
            label="Cancel"
            onClickHandler={() => onChangeSelectAttribute("", {}, "reset")}
            type={0}
            loading={loading}
            id="cancelBtn"
          />
        </div>
        <ProductDetailButton
          label="Go Back"
          onClickHandler={handlePath}
          type={0}
          loading={loading}
          id="goBackBtn"
        />
      </div>
    </>
  );
};

export default ProductDetailActions;
