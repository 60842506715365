import React from "react";
import SearchProductId from "../components/SearchProductId";
import ProductDetail from "../ProductDetail/ProductDetail";

const ProductSearch = (props) => {
  return (
    <ProductDetail {...props} isSearch>
      <SearchProductId idprefix="productDetail" />
    </ProductDetail>
  );
};

export default ProductSearch;
